import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Fragment } from 'react';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  notes: {
    margin: `${theme.spacing(2)} 0`
  }
});

export const NOTES = [
  '※IPアドレスは、ホワイトリスト形式の制限となります。',
  '※IPアドレス制限を推奨しておりますが、制限を設けたくない場合、全通信を許可する設定が必要となります。',
  '※IPアドレス制限を行っておらず、お客様起因でトークン、gift_card_config_code, giftee_box_config_codeが外部へ流出した場合、不正に発行されたギフトについても全て請求対象となりますので、API情報の取り扱いにはご注意ください。',
  '※固定IPをお持ちでない場合は、担当にご連絡ください。'
];

type APIIpRestrictionNotesProps = WithStyles<typeof styles>;

const APIIpRestrictionNotes: React.FC<APIIpRestrictionNotesProps> = ({
  classes
}) => (
  <Fragment>
    {NOTES.map((note, noteIndex) => (
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.notes}
        key={noteIndex}
      >
        {note}
      </Typography>
    ))}
  </Fragment>
);

export default withStyles(styles)(APIIpRestrictionNotes);
