import { Chip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    chip: {
      borderRadius: 5,
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white
    }
  });

type ArchivedChipProps = WithStyles<typeof styles>;

const ArchivedChip: React.FC<ArchivedChipProps> = ({ classes }) => (
  <Chip
    label="アーカイブ済み"
    className={classes.chip}
    data-cy="ArchivedChip"
  />
);

export default withStyles(styles)(ArchivedChip);
