import ReviewRequestMessage from '@console/components/project/ReviewRequestMessage';
import { getSelectedProjectDetail } from '@console/selectors/projectDetail';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const mapStateToProps = (state: AppState) => ({
  selectedProject: getSelectedProjectDetail(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({});

export type ReviewRequestMessagePropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ReviewRequestMessagePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewRequestMessage);
