import { AppActionTypes } from '@console/actions/actionType';
import type { AppAction } from '@console/actions/actionType';

export type ProjectPurposeCategory = {
  urlCode: string;
};

export const initialStateOfProjectPurposeCategory = {
  urlCode: ''
};

export const projectPurposeCategory = (
  state = initialStateOfProjectPurposeCategory,
  action: AppAction
): ProjectPurposeCategory => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfProjectPurposeCategory;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.projectPurposeCategory;
    case AppActionTypes.UPDATE_PROJECT_PURPOSE_CATEGORY:
      return {
        urlCode: action.projectPurposeCategory
      };
    default:
      return state;
  }
};

export default projectPurposeCategory;
