import type { LocalSolutionSystem } from '@console/reducers/projects';

export const isBulkSolutionSystem = (solutionSystem: LocalSolutionSystem) =>
  solutionSystem.solutionSystemIdentifier === 'bulk';

export const isApiSolutionSystem = (solutionSystem: LocalSolutionSystem) =>
  solutionSystem.solutionSystemIdentifier === 'api';

export const isGcpSolutionSystem = (solutionSystem: LocalSolutionSystem) => {
  if (!solutionSystem.solutionSystemIdentifier) {
    return false;
  }
  return (
    !isBulkSolutionSystem(solutionSystem) &&
    !isApiSolutionSystem(solutionSystem)
  );
};
