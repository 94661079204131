import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { FetchResult } from '@apollo/client';

const getProjectPurposeCategoriesGql = gql`
  query ${operationNames.GET_PROJECT_PURPOSE_CATEGORIES}{
    projectPurposeCategories {
      name
      urlCode
      isEmployeeBenefits
    }
  }
`;

export type ProjectPurposeCategory = {
  name: string;
  urlCode: string;
  isEmployeeBenefits: boolean;
};

export type ProjectPurposeCategories = ProjectPurposeCategory[];

export type GetProjectPurposeCategoriesResponse = FetchResult<{
  projectPurposeCategories: ProjectPurposeCategories;
}>;

export const getProjectPurposeCategories =
  (): Promise<GetProjectPurposeCategoriesResponse> =>
    internalApiAppClient.query({
      query: getProjectPurposeCategoriesGql
    });
