import { isFeatureEnable, FeatureTypes } from '@common/lib/featureToggle';
import { isGifteeBoxGiftConfig } from '@console/common/projectDraft/giftConfig';
import {
  isBulkSolutionSystem,
  isApiSolutionSystem,
  isGcpSolutionSystem
} from '@console/common/solutionSystem';
import solutionSystem from '@console/reducers/projectJson/campaignSolutionSystem';
import { RELATION_BETWEEN_HOST_COMPANY_AND_DP } from '@console/reducers/projectJson/hostCompany';
import { createSelector } from 'reselect';
import type { Content } from '../../../common/api/graphql/getContentsGql';
import type {
  SolutionSystem,
  SolutionSystems
} from '../../../common/api/graphql/getSolutionSystemsGql';
import type { AppState } from '../reducers';
import type { MarketingIndustry } from '@common/api/graphql/getMarketingIndustriesGql';
import type { ProjectPurposeCategory } from '@common/api/graphql/getProjectPurposeCategoriesGql';
import type {
  GiftConfig,
  FixedGiftConfig,
  ChoosableGiftConfig,
  GifteeBoxGiftConfig
} from '@console/reducers/projectJson/giftConfigs';

type GiftConfigForDisplayCommon = {
  selectedContents: (Content | undefined)[];
  price: number;
  estimatedAmount: number;
};

export type FixedGiftConfigForDisplay = Omit<
  FixedGiftConfig,
  'selectedContents'
> &
  GiftConfigForDisplayCommon;

export type ChoosableGiftConfigForDisplay = Omit<
  ChoosableGiftConfig,
  'selectedContents'
> &
  GiftConfigForDisplayCommon;

export type GifteeBoxGiftConfigForDisplay = Omit<
  GifteeBoxGiftConfig,
  'selectedContents'
> &
  GiftConfigForDisplayCommon;

export type GiftConfigForDisplay =
  | FixedGiftConfigForDisplay
  | ChoosableGiftConfigForDisplay
  | GifteeBoxGiftConfigForDisplay;

export type ContentWithIsSelected = Content & {
  isSelected: boolean;
};

export type ContentsWithIsSelected = ContentWithIsSelected[];

const getSelectedProjectDraft = (state: AppState) => state.selectedProjectDraft;
const getSelectedProjectDraftCampaignSolutionSystem = (state: AppState) =>
  state.selectedProjectDraft.projectJson.campaignSolutionSystem;
const getSelectedProjectDraftCampaignMarketingIndustry = (state: AppState) =>
  state.selectedProjectDraft.projectJson.campaignMarketingIndustry;
const getSelectedProjectDraftProjectPurposeCategory = (state: AppState) =>
  state.selectedProjectDraft.projectJson.projectPurposeCategory;
const getDistributionPartner = (state: AppState) => state.distributionPartner;
const getSolutionSystems = (state: AppState) => state.solutionSystems;
const getMarketingIndustries = (state: AppState) => state.marketingIndustries;
const getProjectPurposeCategories = (state: AppState) =>
  state.projectPurposeCategories;
const getContents = (state: AppState) => state.contents;
const getSelectedProjectDraftGiftConfigs = (state: AppState) =>
  getSelectedProjectDraft(state).projectJson.giftConfigs;

export const getSolutionSystemsForDisplay = createSelector(
  [getSolutionSystems],
  solutionSystems =>
    // solutionSystemの追加時にfeature-toggleが必要になった場合等を考慮し、selectorとしておく
    getSolutionSystemWithSystemUsageFeeMark(solutionSystems)
);

export const getApiBulkSolutionSystemsForDisplay = createSelector(
  [getSolutionSystems],
  solutionSystems =>
    solutionSystems.filter(
      solutionSystem =>
        isApiSolutionSystem(solutionSystem) ||
        isBulkSolutionSystem(solutionSystem)
    )
);

export const getGcpSolutionSystemsForDisplay = createSelector(
  [getSolutionSystems],
  solutionSystems =>
    solutionSystems
      .filter(solutionSystem => isGcpSolutionSystem(solutionSystem))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
);

const getSolutionSystemWithSystemUsageFeeMark = (
  solutionSystems: SolutionSystems
): SolutionSystems =>
  solutionSystems.map(solutionSystem =>
    isGcpSolutionSystem(solutionSystem)
      ? { ...solutionSystem, name: `${solutionSystem.name} (※)` }
      : solutionSystem
  );

export const getSelectedSolutionSystem = createSelector(
  [getSelectedProjectDraftCampaignSolutionSystem, getSolutionSystems],
  (campaignSolutionSystem, solutionSystems): SolutionSystem | undefined =>
    solutionSystems.find(
      solution => solution.urlCode === campaignSolutionSystem.urlCode
    )
);

const getSelectedProjectDraftHostCompany = createSelector(
  [getSelectedProjectDraft],
  projectDraft => projectDraft.projectJson.hostCompany
);

export const getSelectedProjectDraftHostCompanyName = createSelector(
  [getSelectedProjectDraftHostCompany, getDistributionPartner],
  (hostCompany, distributionPartner): string => {
    switch (hostCompany.relationToDP) {
      case RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL:
        return distributionPartner.name;
      case RELATION_BETWEEN_HOST_COMPANY_AND_DP.OTHER:
        return hostCompany.name || '';
      default:
        return '';
    }
  }
);

export const getSelectedMarketingIndustry = createSelector(
  [getSelectedProjectDraftCampaignMarketingIndustry, getMarketingIndustries],
  (
    campaignMarketingIndustry,
    marketingIndustries
  ): MarketingIndustry | undefined =>
    marketingIndustries.find(
      marketingIndustry =>
        marketingIndustry.urlCode === campaignMarketingIndustry.urlCode
    )
);

export const getSelectedProjectPurposeCategory = createSelector(
  [getSelectedProjectDraftProjectPurposeCategory, getProjectPurposeCategories],
  (
    projectPurposeCategory,
    projectPurposeCategories
  ): ProjectPurposeCategory | undefined =>
    projectPurposeCategories.find(
      category => projectPurposeCategory.urlCode === category.urlCode
    )
);

export const isBulkSolutionSystemSelected = createSelector(
  [getSelectedSolutionSystem],
  (selectedSolutionSystem): boolean =>
    !!selectedSolutionSystem && isBulkSolutionSystem(selectedSolutionSystem)
);

export const isApiSolutionSystemSelected = createSelector(
  [getSelectedSolutionSystem],
  (selectedSolutionSystem): boolean =>
    !!selectedSolutionSystem && isApiSolutionSystem(selectedSolutionSystem)
);

export const isGcpSolutionSystemSelected = createSelector(
  [getSelectedSolutionSystem],
  (selectedSolutionSystem): boolean =>
    !!selectedSolutionSystem && isGcpSolutionSystem(selectedSolutionSystem)
);

export const getSelectedProjectDraftSelectedGiftConfig = createSelector(
  [getSelectedProjectDraft, getSelectedProjectDraftGiftConfigs],
  (projectDraft, giftConfigs): GiftConfig | undefined =>
    giftConfigs[projectDraft.projectJson.selectedGiftConfigIndex]
);

export const getSelectedGiftConfigSelectedGifteeBoxTemplate = createSelector(
  [getSelectedProjectDraftSelectedGiftConfig],
  (giftConfig): GiftConfig['selectedGifteeBoxTemplate'] | undefined =>
    giftConfig?.selectedGifteeBoxTemplate
);

export const getSelectedProjectDraftGiftConfigSelectedContents = createSelector(
  [getSelectedProjectDraftSelectedGiftConfig, getContents],
  (giftConfig, contents): Array<Content | undefined> => {
    if (!giftConfig) return [];
    return giftConfig.selectedContents.map(selectedContentUrlCode =>
      contents.find(content => content.urlCode === selectedContentUrlCode)
    );
  }
);

export const getContentsWithIsSelectedInSelectedGiftConfigInSelectedProjectDraft =
  createSelector(
    [getContents, getSelectedProjectDraftGiftConfigSelectedContents],
    (contents, selectedContents): ContentsWithIsSelected =>
      contents.map(content => ({
        ...content,
        isSelected: selectedContents.some(
          selectedContent => selectedContent?.urlCode === content.urlCode
        )
      }))
  );

export const getSelectedProjectDraftGiftConfigsForDisplay = createSelector(
  [getSelectedProjectDraftGiftConfigs, getContents, getSelectedSolutionSystem],
  (giftConfigs, contents, solutionSystem): GiftConfigForDisplay[] =>
    giftConfigs.map(giftConfig =>
      getGiftConfigForDisplay(giftConfig, contents, solutionSystem)
    )
);

export const getSelectedProjectDraftSelectedGiftConfigForDisplay =
  createSelector(
    [
      getSelectedProjectDraftSelectedGiftConfig,
      getContents,
      getSelectedSolutionSystem
    ],
    (
      giftConfig,
      contents,
      solutionSystem
    ): GiftConfigForDisplay | undefined => {
      if (!giftConfig || !solutionSystem) return undefined;
      return getGiftConfigForDisplay(giftConfig, contents, solutionSystem);
    }
  );

const isGcpGifteeBoxGiftConfig = (
  giftConfig: GiftConfig,
  solutionSystem?: SolutionSystem
) =>
  !!solutionSystem &&
  isGcpSolutionSystem(solutionSystem) &&
  isGifteeBoxGiftConfig(giftConfig);

const isApiGifteeBoxGiftConfig = (
  giftConfig: GiftConfig,
  solutionSystem?: SolutionSystem
) =>
  !!solutionSystem &&
  isApiSolutionSystem(solutionSystem) &&
  isGifteeBoxGiftConfig(giftConfig);

const getGiftConfigForDisplay = (
  giftConfig: GiftConfig,
  contents: Content[],
  solutionSystem?: SolutionSystem
) => {
  const selectedContents = getSelectedContents(
    contents,
    giftConfig.selectedContents
  );

  // GCP、API giftee Box の場合、１つの giftConfig が複数の単価を持つため、確認画面で price を利用しない。
  // そのため、price には 0 を入れておく。
  const price =
    isGcpGifteeBoxGiftConfig(giftConfig, solutionSystem) ||
    isApiGifteeBoxGiftConfig(giftConfig, solutionSystem)
      ? 0
      : getGiftConfigPrice(giftConfig, selectedContents);

  const estimatedAmount = getGiftConfigEstimatedAmount(
    giftConfig,
    price,
    solutionSystem
  );

  return {
    ...giftConfig,
    selectedContents,
    price,
    estimatedAmount
  };
};

const getGiftConfigEstimatedAmount = (
  giftConfig: GiftConfig,
  price: number,
  solutionSystem?: SolutionSystem
): number => {
  if (!solutionSystem) return 0;
  if (
    isGcpGifteeBoxGiftConfig(giftConfig, solutionSystem) ||
    isApiGifteeBoxGiftConfig(giftConfig, solutionSystem)
  ) {
    return giftConfig.gifteeBoxIssueEstimateOptions.reduce(
      (total, gifteeBoxIssueEstimateOption) =>
        total +
        Number(gifteeBoxIssueEstimateOption.issueAmount) *
          Number(gifteeBoxIssueEstimateOption.initialPoint),
      0
    );
  }
  return price * Number(giftConfig.issueAmount);
};

export const getGiftConfigPrice = (
  giftConfig: GiftConfig,
  selectedContents: Content[]
): number => {
  if (isGifteeBoxGiftConfig(giftConfig)) {
    return getGifteeBoxGiftConfigPrice(giftConfig.gifteeBoxInitialPoint);
  } else {
    return getGiftCardGiftConfigPrice(selectedContents);
  }
};

export const getGifteeBoxGiftConfigPrice = (
  gifteeBoxInitialPoint: string
): number => Number(gifteeBoxInitialPoint);

// 選べるギフトの場合、選んだ商品の中で最も高額な商品が、その選べるギフトの単価になります。
// そのため、選択した商品の中で max をとり、それを返しています。
// なお、単一ギフトの場合、商品が１つしか存在しないので、同じ処理でも問題ありません。
export const getGiftCardGiftConfigPrice = (
  selectedContents: Content[]
): number =>
  selectedContents.reduce(
    (maxPrice, content) =>
      !!content ? Math.max(maxPrice, content.price) : maxPrice,
    0
  );

export const getSelectedContents = (
  contents: Content[],
  giftConfigSelectedContents: GiftConfig['selectedContents']
): Content[] =>
  giftConfigSelectedContents.map(
    selectedContentUrlCode =>
      contents.find(content => content.urlCode === selectedContentUrlCode)!
  );

export const getSelectedProjectDraftGiftConfigsEstimatedAmount = createSelector(
  getSelectedProjectDraftGiftConfigsForDisplay,
  (giftConfigsForDisplay): number =>
    giftConfigsForDisplay.reduce(
      (total, giftConfigForDisplay) =>
        total + giftConfigForDisplay.estimatedAmount,
      0
    )
);

const MAX_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_COUNT = 10;

export const isGifteeBoxIssueEstimateOptionAddable = createSelector(
  getSelectedProjectDraftSelectedGiftConfig,
  (giftConfigForDisplay): boolean =>
    !!giftConfigForDisplay &&
    giftConfigForDisplay.gifteeBoxIssueEstimateOptions.length <
      MAX_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_COUNT
);

const MIN_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_COUNT = 1;

export const isGifteeBoxIssueEstimateOptionRemovable = createSelector(
  getSelectedProjectDraftSelectedGiftConfig,
  (giftConfigForDisplay): boolean =>
    !giftConfigForDisplay
      ? false
      : giftConfigForDisplay.gifteeBoxIssueEstimateOptions.length >
        MIN_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION_COUNT
);
