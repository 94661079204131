import { gql } from '@apollo/client';
import { FRAGMENT_CONTENT } from './content';
import { FRAGMENT_GIFTEE_BOX_TEMPLATE } from './gifteeBoxTemplate';
import { FRAGMENT_SOLUTION_SYSTEM } from './solutionSystem';

export const FRAGMENT_PROJECT_GIFT_CARD_CONFIG = gql`
  ${FRAGMENT_CONTENT}
  fragment GiftCardConfigFields on GiftCardConfig {
    urlCode
    unitPrice
    giftWalletStorable
    currentApiGiftCardIssueConfig {
      issuableBeginAt
      issuableEndAt
      estimatedIssueAmount
      urlCode
    }
    messageCardConfig {
      description
      senderName
      mainVisualUrl
      mainVisual {
        urlCode
        imageUrl
      }
    }
    giftCardTicketConfigs {
      fixedContentOption {
        content {
          ...ContentFields
        }
        creativeCheckApproved
      }
      choosableContentOption {
        choices {
          creativeCheckApproved
          content {
            ...ContentFields
          }
          order
        }
      }
    }
    creativeCheckApproved
    giftUsageReviewStatus
    isIssuable
    bulkGiftCardIssueConfigs {
      fixedIssueConfig {
        issueAmount
      }
      deliveryDate
      issueAmountRequest {
        issueAmount
        requestedAt
      }
      requestedIssueAmount
      urlCode
    }
    apiGiftCardIssueConfigs {
      urlCode
      issuableEndAt
      issuableBeginAt
      estimatedIssueAmount
    }
  }
`;

export const FRAGMENT_BULK_GIFT_CARD_DELIVERABLE = gql`
  ${FRAGMENT_PROJECT_GIFT_CARD_CONFIG}
  fragment BulkGiftCardDeliverableFields on BulkGiftCardDeliverable {
    giftCardConfig {
      ...GiftCardConfigFields
    }
    urlCode
    issueAmount
    deliveredAt
    downloadType
    unitPrice
  }
`;

export const FRAGMENT_API_ALLOWED_IP = gql`
  fragment APIAllowedIpFields on APIAllowedIp {
    ip
    urlCode
  }
`;

export const FRAGMENT_ACCESS_TOKEN = gql`
  fragment AccessTokenFields on AccessToken {
    token
  }
`;

// TODO: currentApiGifteeBoxIssueConfig は実体を返すんじゃなくて url_code だけ返すようにして、
// apiGifteeBoxIssueConfigs を 都度 filter してもいいかもしれない
export const FRAGMENT_GIFTEE_BOX_CONFIGS = gql`
  ${FRAGMENT_GIFTEE_BOX_TEMPLATE}
  fragment GifteeBoxConfigsFields on GifteeBoxConfig {
    urlCode
    brandCheckApproved
    giftUsageReviewStatus
    archived
    gifteeBoxTemplate {
      ...GifteeBoxTemplateFields
    }
    bulkGifteeBoxIssueConfigs {
      initialPoint
      deliveryDate
      requestedIssueAmount
      urlCode
      issueAmountRequest {
        issueAmount
        requestedAt
      }
      fixedIssueConfig {
        issueAmount
      }
      gifteeBoxIssueConfig {
        exchangeableEndLabel
      }
    }
    apiGifteeBoxIssueConfigs {
      urlCode
      issuableBeginAt
      issuableEndAt
      issueEstimateOptions {
        urlCode
        initialPoint
        issueAmount
      }
      gifteeBoxIssueConfig {
        exchangeableEndLabel
      }
    }
    messageCardOption {
      description
      imageUrl
    }
    currentApiGifteeBoxIssueConfig {
      urlCode
      issuableBeginAt
      issuableEndAt
      issueEstimateOptions {
        urlCode
        initialPoint
        issueAmount
      }
      gifteeBoxIssueConfig {
        exchangeableEndLabel
      }
    }
  }
`;

export const FRAGMENT_BULK_GIFTEE_BOX_DELIVERABLE = gql`
  ${FRAGMENT_GIFTEE_BOX_CONFIGS}
  fragment BulkGifteeBoxDeliverableFields on BulkGifteeBoxDeliverable {
    gifteeBoxConfig {
      ...GifteeBoxConfigsFields
    }
    urlCode
    issueAmount
    deliveredAt
    downloadType
    initialPoint
    exchangeableEndLabel
  }
`;

export const FRAGMENT_PROJJECT = gql`
  ${FRAGMENT_SOLUTION_SYSTEM}
  ${FRAGMENT_CONTENT}
  ${FRAGMENT_GIFTEE_BOX_TEMPLATE}
  fragment ProjectFields on Project {
    name
    description
    urlCode
    isSample
    hostCompany {
      name
      isOtherCompanyHosting
    }
    advertisingPeriod {
      beginAt
      endAt
    }
    solutionSystem {
      ...SolutionSystemFields
    }
    marketingIndustry {
      name
      urlCode
    }
    projectPurposeCategory {
      name
      urlCode
    }
    projectGiftDistributionChannels {
      name
      urlCode
      description
    }
    projectAdvertisingMediumRegistrations {
      urlCode
      description
    }
    thirdPartyService {
      url
      name
      description
    }
    messageThread {
      urlCode
    }
    giftCardConfigs {
      urlCode
      giftCardTicketConfigs {
        fixedContentOption {
          content {
            ...ContentFields
          }
        }
        choosableContentOption {
          choices {
            content {
              ...ContentFields
            }
            order
          }
        }
      }
    }
    gifteeBoxConfigs {
      urlCode
      gifteeBoxTemplate {
        ...GifteeBoxTemplateFields
      }
    }
    gxItems {
      imageUrl
    }
  }
`;

export const FRAGMENT_PROJECT_DETAIL = gql`
  ${FRAGMENT_CONTENT}
  ${FRAGMENT_SOLUTION_SYSTEM}
  ${FRAGMENT_PROJECT_GIFT_CARD_CONFIG}
  ${FRAGMENT_GIFTEE_BOX_CONFIGS}
  ${FRAGMENT_API_ALLOWED_IP}
  ${FRAGMENT_ACCESS_TOKEN}
  fragment ProjectDetailFields on Project {
    name
    description
    urlCode
    isSample
    isAnyContentPartnerReviewRequested
    isOldReviewProcess
    hostCompany {
      name
      isOtherCompanyHosting
    }
    advertisingPeriod {
      beginAt
      endAt
    }
    giftDistributionPeriod {
      beginAt
      endAt
    }
    solutionSystem {
      ...SolutionSystemFields
    }
    marketingIndustry {
      name
      urlCode
    }
    projectPurposeCategory {
      name
      urlCode
    }
    projectGiftDistributionChannels {
      name
      urlCode
      description
    }
    projectAdvertisingMediumRegistrations {
      urlCode
      description
    }
    thirdPartyService {
      url
      name
      description
    }
    messageThread {
      urlCode
    }
    giftCardConfigs {
      ...GiftCardConfigFields
    }
    claims {
      invoiceIdentity
      invoiceUrlCode
      name
      note
      paid
      paymentDueDate
      totalPaymentAmount
      billingDate
    }
    confidentialInformation {
      __typename
      ... on NullConfidentialInformation {
        reason
      }
      ... on APIConfidentialInformation {
        apiAllowedIps {
          ...APIAllowedIpFields
        }
        accessTokens {
          ...AccessTokenFields
        }
        giftCardConfigs {
          issueCode
          urlCode
        }
        gifteeBoxConfigs {
          issueCode
          urlCode
        }
      }
    }
    gifteeBoxConfigs {
      ...GifteeBoxConfigsFields
    }
  }
`;
