import {
  setSelectedGiftConfig,
  deselectGifteeBoxTemplate,
  selectGifteeBoxTemplateForDraftProject
} from '@console/actions/actions';
import { getClaimEstimateThunk } from '@console/actions/thunks/getClaimEstimate';
import { getSelectedProjectDraftContentsThunk } from '@console/actions/thunks/getSelectedProjectDraftContents';
import ConfirmedContents from '@console/components/projectDraft/confirmation/ConfirmedContents';
import {
  getSelectedSolutionSystem,
  getSelectedProjectDraftHostCompanyName,
  isBulkSolutionSystemSelected,
  isGcpSolutionSystemSelected,
  isApiSolutionSystemSelected,
  getSelectedProjectDraftGiftConfigsForDisplay,
  getSelectedMarketingIndustry,
  getSelectedProjectPurposeCategory,
  getSelectedProjectDraftGiftConfigsEstimatedAmount
} from '@console/selectors/getSelectedProjectDraftForDisplay';
import { connect } from 'react-redux';
import type { AdvertisingMedia } from '@common/api/graphql/getAdvertisingMediaGql';
import type { GiftDistributionChannelCategories } from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';
import type {
  AppThunkAction,
  AppThunkDispatch
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { CampaignAdvertisingMedia } from '@console/reducers/projectJson/campaignAdvertisingMedia';
import type { CampaignGiftDistributionChannelDescriptions } from '@console/reducers/projectJson/campaignGiftDistributionChannelDescriptions';
import type { CampaignGiftDistributionChannels } from '@console/reducers/projectJson/campaignGiftDistributionChannels';
import type { SelectedGifteeBoxTemplate } from '@console/reducers/projectJson/giftConfigs';

const getCampaignGiftDistributionChannelsForDisplay = (
  campaignGiftDistributionChannels: CampaignGiftDistributionChannels,
  giftDistributionChannelCategories: GiftDistributionChannelCategories,
  campaignGiftDistributionChannelDescriptions: CampaignGiftDistributionChannelDescriptions
): string => {
  const result: string[] = [];
  campaignGiftDistributionChannels.forEach(selectedCategoryUrlCode => {
    let description = '';
    const selectedMasterCategories = giftDistributionChannelCategories.filter(
      masterCategory => selectedCategoryUrlCode === masterCategory.urlCode
    );
    if (selectedMasterCategories.length > 0) {
      description += selectedMasterCategories[0].name;

      const descriptions = campaignGiftDistributionChannelDescriptions.filter(
        channelDescription =>
          selectedCategoryUrlCode === channelDescription.urlCode
      );

      if (descriptions.length > 0) {
        description += `:${descriptions[0].description}`;
      }
      result.push(description);
    }
  });
  return result.join(',  ');
};

const getCampaignAdvertisingMediaForDisplay = (
  advertisingMedia: AdvertisingMedia,
  campaignAdvertisingMedia: CampaignAdvertisingMedia
): string => {
  const result: string[] = [];
  campaignAdvertisingMedia.forEach(campaignAdvertisingMedium => {
    let mediumString = '';
    const selectedMasterMedia = advertisingMedia.find(
      masterMedium => campaignAdvertisingMedium.urlCode === masterMedium.urlCode
    );
    if (selectedMasterMedia) {
      mediumString += selectedMasterMedia.name;

      if (campaignAdvertisingMedium.description) {
        mediumString += `:${campaignAdvertisingMedium.description}`;
      }

      result.push(mediumString);
    }
  });
  return result.join(',  ');
};

const onMount = (): AppThunkAction<void> => dispatch => {
  dispatch(getSelectedProjectDraftContentsThunk()).catch(() => {});
};

const selectGiftConfig =
  (giftConfigIndex: number): AppThunkAction<void> =>
  dispatch => {
    dispatch(setSelectedGiftConfig(giftConfigIndex));
  };

const handleProjectDraftGiftConfigsEstimatedAmountTotalChange =
  (): AppThunkAction<void> => (dispatch, getState) => {
    const projectDraftGiftConfigsEstimatedAmountTotal =
      getSelectedProjectDraftGiftConfigsEstimatedAmount(getState());
    dispatch(
      getClaimEstimateThunk(projectDraftGiftConfigsEstimatedAmountTotal)
    ).catch(() => {});
  };

const mapStateToProps = (state: AppState) => ({
  projectJson: state.selectedProjectDraft.projectJson,
  projectDraftSolutionSystem: getSelectedSolutionSystem(state),
  projectDraftHostCompanyName: getSelectedProjectDraftHostCompanyName(state),
  projectDraftMarketingIndustry: getSelectedMarketingIndustry(state),
  projectDraftProjectPurposeCategory: getSelectedProjectPurposeCategory(state),
  isBulkSolutionSystemSelected: isBulkSolutionSystemSelected(state),
  isGcpSolutionSystemSelected: isGcpSolutionSystemSelected(state),
  isApiSolutionSystemSelected: isApiSolutionSystemSelected(state),
  projectDraftGiftConfigs: getSelectedProjectDraftGiftConfigsForDisplay(state),
  campaignGiftDistributionChannelsForDisplay:
    getCampaignGiftDistributionChannelsForDisplay(
      state.selectedProjectDraft.projectJson.campaignGiftDistributionChannels,
      state.giftDistributionChannelCategories,
      state.selectedProjectDraft.projectJson
        .campaignGiftDistributionChannelDescriptions
    ),
  campaignAdvertisingMediaForDisplay: getCampaignAdvertisingMediaForDisplay(
    state.advertisingMedia,
    state.selectedProjectDraft.projectJson.campaignAdvertisingMedia
  ),
  projectDraftGiftConfigsEstimatedAmountTotal:
    getSelectedProjectDraftGiftConfigsEstimatedAmount(state),
  projectDraftEstimatedAmount:
    state.selectedProjectDraft.projectJson.claimEstimate.totalPrice ?? 0,
  issueRateAmount:
    state.selectedProjectDraft.projectJson.claimEstimate.issueCommissionPrice,
  consumerTaxAmount:
    state.selectedProjectDraft.projectJson.claimEstimate.consumptionTaxPrice ??
    0
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onMount: () => dispatch(onMount()),
  selectGiftConfig: (giftConfigIndex: number) =>
    dispatch(selectGiftConfig(giftConfigIndex)),
  handleProjectDraftGiftConfigsEstimatedAmountTotalChange: () =>
    dispatch(handleProjectDraftGiftConfigsEstimatedAmountTotalChange()),
  selectGifteeBoxTemplate: (
    gifteeBoxTemplate: NonNullable<SelectedGifteeBoxTemplate>
  ) => dispatch(selectGifteeBoxTemplateForDraftProject(gifteeBoxTemplate)),
  deselectGifteeBoxTemplate: () => dispatch(deselectGifteeBoxTemplate())
});

export type ConfirmedContentsPropsMappedFromState = ReturnType<
  typeof mapStateToProps
>;
export type ConfirmedContentsPropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedContents);
