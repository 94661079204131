import {
  QueryGiftUsageReviewStatusTypes,
  type QueryApiGiftCardIssueConfig
} from '@common/api/graphql/getProjectsGql';
import { isGiftCardChoosableTicketConfig } from '@console/common/project/giftCardTicketConfig';
import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type {
  LocalApiGiftCardIssueConfig,
  LocalGiftCardConfig,
  LocalProject
} from '@console/reducers/projects';

export type GiftCardConfigForDisplay = LocalGiftCardConfig & {
  isGiftCardPreviewVisible: boolean;
  lastApiGiftCardIssueConfig: QueryApiGiftCardIssueConfig | undefined;
};

const getProjectDetailGiftCardConfigs = (state: AppState) =>
  state.projectDetail.giftCardConfigs;

export const getGiftCardConfigs = createSelector(
  [getProjectDetailGiftCardConfigs],
  (giftCardConfigs): LocalProject['giftCardConfigs'] => giftCardConfigs ?? []
);

export const getGiftCardConfigsForDisplay = createSelector(
  [getGiftCardConfigs],
  (giftCardConfigs): GiftCardConfigForDisplay[] =>
    giftCardConfigs.map(giftCardConfig =>
      getGiftCardConfigForDisplay(giftCardConfig)
    )
);

const getGiftCardConfigForDisplay = (
  giftCardConfig: LocalGiftCardConfig
): GiftCardConfigForDisplay => ({
  ...giftCardConfig,
  isGiftCardPreviewVisible: isGiftCardPreviewVisible(giftCardConfig),
  lastApiGiftCardIssueConfig: getLastApiGiftCardIssueConfig(
    giftCardConfig.apiGiftCardIssueConfigs
  )
});

export const isGiftCardPreviewVisible = (
  giftCardConfig: LocalGiftCardConfig
): boolean => {
  if (giftCardConfig.giftCardTicketConfigs.length > 1) {
    return true;
  }

  const firstGiftCardTicketConfig = giftCardConfig.giftCardTicketConfigs[0];
  if (
    !!firstGiftCardTicketConfig &&
    isGiftCardChoosableTicketConfig(firstGiftCardTicketConfig)
  ) {
    return true;
  }
  return !!giftCardConfig.messageCardConfig;
};

const getLastApiGiftCardIssueConfig = (
  apiGiftCardIssueConfigs: LocalGiftCardConfig['apiGiftCardIssueConfigs']
): LocalApiGiftCardIssueConfig | undefined =>
  apiGiftCardIssueConfigs.sort((a, b) => {
    if (a.issuableEndAt < b.issuableEndAt) return 1;
    if (a.issuableEndAt > b.issuableEndAt) return -1;

    return 0;
  })[0];

const getSelectedGiftCardConfigUrlCode = (state: AppState) =>
  state.selectedGiftCardConfigUrlCode;

export const getSelectedGiftCardConfig = createSelector(
  [getGiftCardConfigs, getSelectedGiftCardConfigUrlCode],
  (giftCardConfigs, giftCardConfigUrlCode): LocalGiftCardConfig | undefined =>
    giftCardConfigs.find(
      giftCardConfig => giftCardConfig.urlCode === giftCardConfigUrlCode
    )
);

export const isGiftUsageReviewStatusApproved = (
  giftUsageReviewStatus: string
): boolean =>
  giftUsageReviewStatus === QueryGiftUsageReviewStatusTypes.APPROVED;
