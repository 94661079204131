import { getLocalString } from '@common/lib/getLocalString';
import { MIN_CHOOSABLE_GIFT_CONTENTS_STRING } from '@console/common/constants';
import type { SupportedPoint } from '@common/api/graphql/getGifteeBoxTemplatesGql';

export const createErrorMessageForEmpty = (key: string): string =>
  `${key}を入力してください。`;

export const createErrorMessageForInputAgain = (key: string): string =>
  `${key}をもう一度入力してください。`;

export const createErrorMessageForArrayEmpty = (key: string): string =>
  `${key}は１つ以上追加してください。`;

export const createErrorMessageForChoosableGiftConfigSelectedContentsLength = (
  key: string
): string =>
  `${key}を${MIN_CHOOSABLE_GIFT_CONTENTS_STRING}種類以上選択してください。`;

export const createErrorMessageForIsNumeric = (key: string): string =>
  `${key}は数字(半角)で入力してください。`;

export const createErrorMessageForIsLength = (
  key: string,
  length: number
): string => `${key}は${length}桁で入力してください。`;

export const createErrorMessageForUnselected = (key: string): string =>
  `${key}を選択してください。`;

export const createErrorMessageForPhoneLength = (key: string): string =>
  `${key}は10桁または11桁で入力してください。`;

export const createErrorMessageForPasswordLength = (key: string): string =>
  `${key}は8〜50桁で入力してください。`;

export const createErrorMessageForPasswordFirstCharacter = (
  key: string
): string => `${key}は半角英字、数字で始まる文字を入力してください。`;

export const createErrorMessageForPasswordValidCharacter = (
  key: string
): string =>
  `${key}は無効な文字を含んでいます。利用可能な文字は半角英字、半角数字、記号('-', '_')です。`;

export const createErrorMessageForPasswordSafety = (key: string): string =>
  `安全な${key}のため、大文字、小文字、数字をそれぞれ含む必要があります。`;

export const createErrorMessageForGreaterThan = (key: string, value): string =>
  `${key}は${value}より大きい値で入力してください。`;

export const createErrorMessageForPastDate = (key: string): string =>
  `${key}は未来の日付を入力してください。`;

export const createErrorMessageForProjectDraftFutureDate = (
  key: string,
  value: string
): string =>
  `${key}は${value}以内の日付を入力してください。${value}以上の案件は申込後ご相談ください。`;

export const createErrorMessageForDateConsistency = (
  startKey: string,
  endKey: string
): string => `${endKey}は${startKey}より後の日付を入力してください。`;

export const createErrorMessageForDateDuration = (
  startKey: string,
  endKey: string,
  duration: string
): string => `${endKey}は${startKey}から${duration}の日付を入力してください。`;

export const createErrorMessageForEmojiIncluded = (key: string): string =>
  `${key}に絵文字を含めることはできません。`;

export const ERROR_MESSAGE_FOR_PAGE_VALIDATION =
  '恐れ入りますが、もう一度入力内容をご確認ください。';

export const ERROR_MESSAGE_FOR_DUPLICATE_CAMPAIGN_NAMES =
  '過去同一のキャンペーン名でお申込みいただいております。過去お申込みいただいたものと区別がつくよう、キャンペーン名のご調整をお願いいたします。';

export const createErrorForGifteeBoxInitialPoint = (
  key: string,
  supportedPoint: SupportedPoint
): string =>
  `${key}は${getLocalString(supportedPoint.minPoint)}以上、${getLocalString(
    supportedPoint.maxPoint
  )}以下の${supportedPoint.step}の倍数で入力してください。`;

export const createErrorForDuplicateEntries = (key: string): string =>
  `同一${key}を複数設定することはできません。`;

export const createErrorMessageInvalidUrlFormat = (key: string): string =>
  `${key}はURL形式で入力してください。`;

export const ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_PROJECT_DRAFT =
  'お申し込みの合計金額が本アカウントのご利用限度額を超えています。弊社担当までご一報ください。';
export const ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_REQUEST_ISSUE_AMOUNT =
  'お申し込みの合計金額が本アカウントのご利用限度額を超えています。右下のサポートもしくは弊社担当までご一報ください。';
export const ERROR_MESSAGE_FOR_EXCEED_CREDIT_LIMIT_IN_ADDING_ISSUE_CONFIGS =
  'お申し込みの合計金額が本アカウントのご利用限度額を超えています。右下のサポートもしくは弊社担当までご一報ください。';
