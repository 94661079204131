import { hasGon } from '@common/lib/gon';
import type { Gon } from '@console/common/window';

const getLoupeEndpoint = (): string => {
  if (hasGon<Gon>(window)) {
    const { loupeEndpoint } = window.gon;
    return loupeEndpoint || '';
  } else {
    return '';
  }
};

export const getLoupeRequestManagementProjectHomeUrl = (
  projectUrlCode: string,
  sessionToken: string
): string =>
  `${getLoupeEndpoint()}/request_management/projects/${projectUrlCode}/home?provider_name=ikedayama&session_token=${sessionToken}`;
