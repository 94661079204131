import { getAdvertisingMedia } from '@common/api/graphql/getAdvertisingMediaGql';
import { getBrands } from '@common/api/graphql/getBrandsGql';
import {
  getContents,
  GetContentsResponse
} from '@common/api/graphql/getContentsGql';
import { getDefaultGiftCardConfig } from '@common/api/graphql/getDefaultGiftCardConfig';
import { getGiftCardMainVisuals } from '@common/api/graphql/getGiftCardMainVisualsGql';
import { getGiftDistributionChannelCategories } from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';
import { getMarketingIndustries } from '@common/api/graphql/getMarketingIndustriesGql';
import { getPrefectures } from '@common/api/graphql/getPrefecturesGql';
import { getProjectPurposeCategories } from '@common/api/graphql/getProjectPurposeCategoriesGql';
import { getSolutionSystems } from '@common/api/graphql/getSolutionSystemsGql';
import {
  getMasterDataStart,
  getMasterDataSuccess,
  getMasterDataFailure
} from '../actions';
import handleApiErrorIfNeeded from './handleApiErrorIfNeeded';
import type { AppThunkAction } from '../thunkType';
import type { ApiError } from '@common/api/graphql/apolloClient';
import type { GetAdvertisingMediaResponse } from '@common/api/graphql/getAdvertisingMediaGql';
import type { GetBrandsResponse } from '@common/api/graphql/getBrandsGql';
import type { GetDefaultGiftCardConfigResponse } from '@common/api/graphql/getDefaultGiftCardConfig';
import type { GetGiftCardMainVisualsResponse } from '@common/api/graphql/getGiftCardMainVisualsGql';
import type { GetGiftDistributionChannelCategoriesResponse } from '@common/api/graphql/getGiftDistributionChannelCategoriesGql';
import type { GetMarketingIndustriesResponse } from '@common/api/graphql/getMarketingIndustriesGql';
import type { GetPrefecturesResponse } from '@common/api/graphql/getPrefecturesGql';
import type { GetProjectPurposeCategoriesResponse } from '@common/api/graphql/getProjectPurposeCategoriesGql';
import type { GetSolutionSystemsResponse } from '@common/api/graphql/getSolutionSystemsGql';

export const getMasterDataSuccessThunk =
  (
    brandsResponse: GetBrandsResponse,
    giftCardDefaultMainVisualsResponse: GetGiftCardMainVisualsResponse,
    solutionSystemsResponse: GetSolutionSystemsResponse,
    marketingIndustriesResponse: GetMarketingIndustriesResponse,
    projectPurposeCategoriesResponse: GetProjectPurposeCategoriesResponse,
    giftDistributionChannelCategoriesResponse: GetGiftDistributionChannelCategoriesResponse,
    advertisingMediaResponse: GetAdvertisingMediaResponse,
    defaultGiftCardConfigResponse: GetDefaultGiftCardConfigResponse,
    prefecturesResponse: GetPrefecturesResponse
  ): AppThunkAction<Promise<void>> =>
  dispatch => {
    const { brands } = brandsResponse.data!;
    const { giftCardDefaultMainVisuals } =
      giftCardDefaultMainVisualsResponse.data!;
    const { solutionSystems } = solutionSystemsResponse.data!;
    const { marketingIndustries } = marketingIndustriesResponse.data!;
    const { projectPurposeCategories } = projectPurposeCategoriesResponse.data!;
    const { giftDistributionChannelCategories } =
      giftDistributionChannelCategoriesResponse.data!;
    const { advertisingMedia } = advertisingMediaResponse.data!;
    const { defaultGiftCardConfig } = defaultGiftCardConfigResponse.data!;
    const { prefectures } = prefecturesResponse.data!;
    dispatch(
      getMasterDataSuccess(
        brands,
        giftCardDefaultMainVisuals,
        solutionSystems,
        marketingIndustries,
        projectPurposeCategories,
        giftDistributionChannelCategories,
        advertisingMedia,
        defaultGiftCardConfig,
        prefectures
      )
    );
    return Promise.resolve();
  };

export const getMasterDataFailureThunk =
  (error: ApiError): AppThunkAction<Promise<void>> =>
  dispatch => {
    dispatch(getMasterDataFailure());
    return dispatch(handleApiErrorIfNeeded(error));
  };

export const getMasterDataThunk =
  (): AppThunkAction<Promise<void>> => dispatch => {
    dispatch(getMasterDataStart());
    return Promise.all([
      getBrands(),
      getGiftCardMainVisuals(),
      getSolutionSystems(),
      getMarketingIndustries(),
      getProjectPurposeCategories(),
      getGiftDistributionChannelCategories(),
      getAdvertisingMedia(),
      getDefaultGiftCardConfig(),
      getPrefectures()
    ])
      .then(
        ([
          brandsResponse,
          giftCardDefaultMainVisualsResponse,
          solutionSystemsResponse,
          marketingIndustriesResponse,
          projectPurposeCategoriesResponse,
          giftDistributionChannelCategoriesResponse,
          advertisingMediaResponse,
          defaultGiftCardConfigResponse,
          prefecturesResponse
        ]) =>
          dispatch(
            getMasterDataSuccessThunk(
              brandsResponse,
              giftCardDefaultMainVisualsResponse,
              solutionSystemsResponse,
              marketingIndustriesResponse,
              projectPurposeCategoriesResponse,
              giftDistributionChannelCategoriesResponse,
              advertisingMediaResponse,
              defaultGiftCardConfigResponse,
              prefecturesResponse
            )
          )
      )
      .catch(error => dispatch(getMasterDataFailureThunk(error)));
  };
