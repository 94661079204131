import { hasGon } from '@common/lib/gon';
import { isGoogleTagManagerEnabled } from '@common/lib/googleTagManager';
import { isZendeskWidgetEnabled } from '@common/lib/zendeskWidget';

export interface Gon {
  zendeskWidgetAccountKey?: string;
  g4bMyPageEndpoint?: string;
  loupeEndpoint?: string;
  eraberuPayGifteeBoxTemplateUrlCode?: string;
  googleTagManagerEnable?: boolean;
  googleTagManager?: {
    containerId: string;
    auth: string;
    preview: string;
  };
}

interface ConsoleWindowWithZendeskWidget
  extends WindowWithZendeskWidget,
    WindowWithGon<Gon> {}

export const isWindowWithZendeskWidget = (
  window: Window
): window is ConsoleWindowWithZendeskWidget =>
  isZendeskWidgetEnabled(window) && hasGon<Gon>(window);

interface ConsoleWindow
  extends WindowWithGon<Gon>,
    WindowWithGoogleTagManager<GoogleTagManagerDataLayer> {}

export const isConsoleWindow = (window: Window): window is ConsoleWindow =>
  hasGon(window) && isGoogleTagManagerEnabled(window);
