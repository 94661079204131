import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';

export const enum ProjectDraftSyncStatuses {
  INITIAL = 'initial',
  NOT_SYNCED = 'not synced',
  SYNCED = 'synced',
  SYNCING = 'syncing'
}

type ProjectDraftSyncStatus = 'initial' | 'not synced' | 'synced' | 'syncing';

const projectDraftSyncStatus = (
  state = ProjectDraftSyncStatuses.INITIAL,
  action: AppAction
): ProjectDraftSyncStatus => {
  switch (action.type) {
    case AppActionTypes.UPDATE_PROJECT_DRAFT_FAILURE:
    case AppActionTypes.UPDATE_CAMPAIGN_NAME:
    case AppActionTypes.UPDATE_CAMPAIGN_DESCRIPTION:
    case AppActionTypes.UPDATE_HOST_COMPANY_RELATION_TO_DP:
    case AppActionTypes.UPDATE_HOST_COMPANY_NAME:
    case AppActionTypes.UPDATE_CAMPAIGN_MARKETING_INDUSTRY:
    case AppActionTypes.UPDATE_PROJECT_PURPOSE_CATEGORY:
    case AppActionTypes.UPDATE_START_DATE_OF_AD:
    case AppActionTypes.UPDATE_END_DATE_OF_AD:
    case AppActionTypes.UPDATE_START_DATE_OF_DISTRIBUTION:
    case AppActionTypes.UPDATE_END_DATE_OF_DISTRIBUTION:
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK:
    case AppActionTypes.UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP:
    case AppActionTypes.TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL:
    case AppActionTypes.UPDATE_GIFT_DISTRIBUTION_CHANNEL_DESCRIPTION:
    case AppActionTypes.TOGGLE_CAMPAIGN_ADVERTISING_MEDIUM:
    case AppActionTypes.UPDATE_CAMPAIGN_ADVERTISING_MEDIUM_DESCRIPTION:
    case AppActionTypes.UPDATE_NAME_OF_THIRD_PARTY_SERVICE:
    case AppActionTypes.UPDATE_URL_OF_THIRD_PARTY_SERVICE:
    case AppActionTypes.UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE:
    case AppActionTypes.ADD_NEW_GIFT_CONFIG:
    case AppActionTypes.DELETE_GIFT_CONFIG:
    case AppActionTypes.UPDATE_GIFT_CONFIG_TICKET_TYPE:
    case AppActionTypes.ADD_GIFT_CONFIG_SELECTED_CONTENTS:
    case AppActionTypes.REMOVE_GIFT_CONFIG_SELECTED_CONTENTS:
    case AppActionTypes.ADD_GIFT_CONFIG_SELECTED_CONTENTS:
    case AppActionTypes.REMOVE_GIFT_CONFIG_SELECTED_CONTENTS:
    case AppActionTypes.UPDATE_GIFT_CONFIG_ISSUE_AMOUNT:
    case AppActionTypes.UPDATE_GIFT_CONFIG_DELIVERY_DATE:
    case AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING:
    case AppActionTypes.UPDATE_GIFT_CONFIG_SENDER_NAME:
    case AppActionTypes.UPDATE_GIFT_CONFIG_MESSAGE:
    case AppActionTypes.UPDATE_GIFT_CONFIG_MAIN_VISUAL:
    case AppActionTypes.GET_CLAIM_ESTIMATE_SUCCESS:
    case AppActionTypes.SELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE:
    case AppActionTypes.DESELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE:
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_INITIAL_POINT:
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_INITIAL_POINT:
    case AppActionTypes.UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_ISSUE_AMOUNT:
    case AppActionTypes.ADD_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION:
    case AppActionTypes.REMOVE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION:
      return ProjectDraftSyncStatuses.NOT_SYNCED;
    case AppActionTypes.UPDATE_PROJECT_DRAFT_START:
      return ProjectDraftSyncStatuses.SYNCING;
    case AppActionTypes.UPDATE_PROJECT_DRAFT_SUCCESS:
      return ProjectDraftSyncStatuses.SYNCED;
    case AppActionTypes.INITIALIZE_PROJECT_DRAFT_SYNC_STATUS:
      return ProjectDraftSyncStatuses.INITIAL;
    default:
      return state;
  }
};

export default projectDraftSyncStatus;
