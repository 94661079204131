import { createSelector } from 'reselect';
import type { AppState } from '@console/reducers';
import type { LocalProject } from '@console/reducers/projects';

const getProjectDetailProjectPurposeCategory = (state: AppState) =>
  state.projectDetail.projectPurposeCategory;

export const getProjectPurposeCategory = createSelector(
  [getProjectDetailProjectPurposeCategory],
  (projectPurposeCategory): LocalProject['projectPurposeCategory'] => ({
    name: projectPurposeCategory.name ?? '',
    urlCode: projectPurposeCategory.urlCode ?? ''
  })
);
