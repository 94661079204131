import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import type {
  ReviewRequestMessagePropsMappedFromState,
  ReviewRequestMessagePropsMappedFromDispatch
} from '../../containers/project/ReviewRequestMessageContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    info: {
      padding: theme.spacing(),
      border: `solid 1px rgb(0, 151, 245)`,
      borderRadius: 5,
      backgroundColor: 'rgb(219, 241, 255)'
    },
    typography: {
      margin: `${theme.spacing(1)}`
    },
    section: {
      paddingTop: theme.spacing(3)
    }
  });

type ReviewRequestMessageProps = ReviewRequestMessagePropsMappedFromState &
  ReviewRequestMessagePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const ReviewRequestMessage: React.FC<ReviewRequestMessageProps> = ({
  classes,
  selectedProject
}) => (
  <>
    {!selectedProject?.isAnyContentPartnerReviewRequested && (
      <section className={classes.section}>
        <Grid
          container
          item
          wrap="nowrap"
          alignItems="center"
          xs={12}
          className={classes.info}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.typography}
          >
            ギフトを利用するには審査が必要です。クリエイティブのご提出をお願いします。
          </Typography>
        </Grid>
      </section>
    )}
  </>
);

export default withStyles(styles)(ReviewRequestMessage);
