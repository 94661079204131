const operationNames = {
  GET_PROJECT_BY_NAME: 'GetProjectByName',
  GET_PROJECT_DETAIL: 'GetProjectDetail',
  GET_PROJECT_BULK_DELIVERABLES: 'GetProjectBulkDeliverables',
  GET_PROJECT_DRAFTS: 'GetProjectDrafts',
  REQUEST_EMAIL_AUTHENTICATION: 'RequestEmailAuthentication',
  AUTHENTICATE_DISTRIBUTION_PARTNER_AUTHENTICATION_REQUEST:
    'AuthenticateDistributionPartnerAuthenticationRequest',
  REGISTER_DISTRIBUTION_PARTNER: 'RegisterDistributionPartner',
  ADD_API_ALLOWED_IP: 'AddApiAllowedIp',
  AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE:
    'AggregateGifteeBoxSelectedContentPercentage',
  CHOOSE_GIFT_CARD_TICKET_CHOICE: 'ChooseGiftCardTicketChoice',
  CREATE_PROJECT: 'CreateProject',
  CREATE_SAMPLE_PROJECT: 'CreateSampleProject',
  DELETE_API_ALLOWED_IP: 'DeleteApiAllowedIp',
  DELETE_PROJECT_DRAFT: 'DeleteProjectDraft',
  EXCHANGE_POINT_FOR_GIFTS: 'ExchangePointForGifts',
  GET_ADVERTISING_MEDIA: 'GetAdvertisingMedia',
  GET_BRANDS: 'GetBrands',
  GET_CLAIM_ESTIMATE: 'GetClaimEstimate',
  GET_CONTENT: 'GetContent',
  GET_CONTENTS: 'GetContents',
  GET_DEFAULT_GIFT_CARD_CONFIG: 'GetDefaultGiftCardConfig',
  GET_GIFT_CARD_DEFAULT_MAIN_VISUALS: 'GetGiftCardDefaultMainVisuals',
  GET_GIFT_CARD: 'GetGiftCard',
  GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES:
    'GetGiftDistributionCHANNEL_CATEGORIES',
  GET_GIFTEE_BOX_TEMPLATE_BRANDS: 'GetGifteeBoxTemplateBrands',
  GET_GIFTEE_BOX_TEMPLATE_CONTENT_SELECTIONS:
    'GetGifteeBoxTemplateContentSelections',
  GET_GIFTEE_BOX_TEMPLATES: 'GetGifteeBoxTemplates',
  GET_ISSUE_COUNTS: 'GetIssueCounts',
  GET_LOGIN_USER: 'GetLoginUser',
  GET_MARKETING_INDUSTRIES: 'GetMarketingIndustries',
  GET_MESSAGE_THREAD: 'GetMessageThread',
  GET_PREFECTURES: 'GetPrefectures',
  GET_PROJECTS: 'GetProjects',
  GET_PROJECT_PURPOSE_CATEGORIES: 'GetProjectPurposeCategories',
  GET_SOLUTION_SYSTEMS: 'GetSolutionSystems',
  GET_GENERAL_ANNOUNCEMENTS: 'GetGeneralAnnouncements',
  AUTHENTICATE: 'Authenticate',
  REFRESH_TOKEN: 'RefreshToken',
  POST_MESSAGE_THREAD_MESSAGE: 'PostMessageThreadMessage',
  REGISTER_PROJECT_DRAFT: 'RegisterProjectDraft',
  REQUEST_BULK_DELIVERABLE_URL: 'RequestBulkDeliverableUrl',
  REQUEST_GIFT_CARD_ISSUE_AMOUNT: 'RequestGiftCardIssueAmount',
  REQUEST_GIFTEE_BOX_ISSUE_AMOUNT: 'RequestGifteeBoxIssueAmount',
  REQUEST_INVOICE_PDF_URL: 'RequestInvoicePdfUrl',
  REQUEST_MESSAGE_THREAD_FILE_URL: 'RequestMessageThreadFileUrl',
  REQUEST_PASSWORD_RESET: 'RequestPasswordReset',
  RESET_PASSWORD: 'ResetPassword',
  SEARCH_POST_CODES: 'SearchPostcodes',
  UPDATE_API_ALLOWED_IP: 'UpdateApiAllowedIp',
  UPDATE_DEFAULT_BILLING_ADDRESS: 'UpdateDefaultBillingAddress',
  UPDATE_DISTRIBUTION_PARTNER_USER: 'UpdateDistributionPartnerUser',
  UPDATE_PROJECT_DRAFT: 'UpdateProjectDraft',
  UPLOAD_FILE: 'UploadFile',
  ADD_BULK_GIFT_CARD_ISSUE_CONFIGS: 'AddBulkGiftCardIssueConfigs',
  ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS: 'AddBulkGifteeBoxIssueConfigs'
} as const;

export { operationNames };
