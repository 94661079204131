import {
  updateEditApiAllowedIpAddress,
  exitApiAllowedIpAddModal
} from '@console/actions/actions';
import { addApiAllowedIpThunk } from '@console/actions/thunks/addApiAllowedIp';
import { deleteApiAllowedIpThunk } from '@console/actions/thunks/deleteApiAllowedIp';
import { getProjectDetailThunk } from '@console/actions/thunks/getProjectDetail';
import { updateApiAllowedIpThunk } from '@console/actions/thunks/updateApiAllowedIp';
import Table from '@console/components/project/apiIpRestriction/Table';
import { getSelectedProjectDetail } from '@console/selectors/projectDetail';
import { connect } from 'react-redux';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';
import type {
  AppThunkDispatch,
  AppThunkAction
} from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';

const updateApiAllowedIp =
  (apiAllowedIp: QueryApiAllowedIp): AppThunkAction<void> =>
  (dispatch, getState) => {
    const projectUrlCode = getSelectedProjectDetail(getState()).urlCode;

    dispatch(updateApiAllowedIpThunk(projectUrlCode, apiAllowedIp))
      .then(() => dispatch(getProjectDetailThunk(projectUrlCode)))
      .catch(() => {});
  };

const deleteApiAllowedIp =
  (apiAllowedIpUrlCode: UrlCode): AppThunkAction<void> =>
  (dispatch, getState) => {
    const projectUrlCode = getSelectedProjectDetail(getState()).urlCode;

    dispatch(deleteApiAllowedIpThunk(projectUrlCode, apiAllowedIpUrlCode))
      .then(() => dispatch(getProjectDetailThunk(projectUrlCode)))
      .catch(() => {});
  };

const addApiAllowedIp =
  (ip: QueryApiAllowedIp['ip']): AppThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const projectUrlCode = getSelectedProjectDetail(getState()).urlCode;

    return dispatch(addApiAllowedIpThunk(projectUrlCode, ip))
      .then(() => dispatch(getProjectDetailThunk(projectUrlCode)))
      .catch(error => Promise.reject(error));
  };

const mapStateToProps = (state: AppState) => ({
  apiAllowedIps: state.edits.project.apiAllowedIps
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onChangeApiAllowedIpAddress: (
    event: GenericChangeEvent<string>,
    apiAllowedIpUrlCode: UrlCode
  ) =>
    dispatch(
      updateEditApiAllowedIpAddress(event.target.value, apiAllowedIpUrlCode)
    ),
  updateApiAllowedIp: (apiAllowedIp: QueryApiAllowedIp) =>
    dispatch(updateApiAllowedIp(apiAllowedIp)),
  deleteApiAllowedIp: (apiAllowedIpUrlCode: UrlCode) =>
    dispatch(deleteApiAllowedIp(apiAllowedIpUrlCode)),
  addApiAllowedIp: (ip: QueryApiAllowedIp['ip']) =>
    dispatch(addApiAllowedIp(ip)),
  onExitAddModal: () => dispatch(exitApiAllowedIpAddModal())
});

export type TablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type TablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
