import { AppActionTypes } from '../actions/actionType';
import { RELATION_BETWEEN_HOST_COMPANY_AND_DP } from './projectJson/hostCompany';
import type { AppAction } from '../actions/actionType';
import type {
  BILLING_ADDRESS_NAME,
  BILLING_ADDRESS_DIVISION,
  BILLING_ADDRESS_POSTAL_CODE,
  BILLING_ADDRESS_PREFECTURE,
  BILLING_ADDRESS_CITY,
  BILLING_ADDRESS_ADDRESS
} from '../middleware/validator/account/distributionPartnerEdit';
import type {
  DISTRIBUTION_PARTNER_USER_NAME,
  DISTRIBUTION_PARTNER_USER_DIVISION,
  DISTRIBUTION_PARTNER_USER_PHONE_NUMBER
} from '../middleware/validator/account/distributionPartnerUserEdit';

export type ErrorMessage = string;

export const PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP =
  'projectDraftCampaignOverview';
export const PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  HOST_COMPANY: 'hostCompany',
  START_DATE_OF_AD: 'startDateOfAd',
  END_DATE_OF_AD: 'endDateOfAd',
  DATE_CONSISTENCY_OF_AD: 'dateConsistencyOfAd',
  DATE_DURATION_OF_AD: 'dateDurationOfAd',
  START_DATE_OF_DISTRIBUTION: 'startDateOfDistribution',
  END_DATE_OF_DISTRIBUTION: 'endDateOfDistribution',
  DATE_CONSISTENCY_OF_DISTRIBUTION: 'dateConsistencyOfDistribution',
  DATE_DURATION_OF_DISTRIBUTION: 'dateDurationOfDistribution',
  CAMPAIGN_SOLUTION: 'campaignSolutionSystem',
  PROJECT_PURPOSE_CATEGORY: 'projectPurposeCategory',
  CAMPAIGN_MARKETING_INDUSTRY: 'campaignMarketingIndustry'
} as const;

type projectDraftCampaignOverviewErrorKeys =
  (typeof PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS)[keyof typeof PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_KEYS];

type validateStatesOfProjectDraftGifDistribution =
  | 'campaignGiftDistributionChannels'
  | 'campaignAdvertisingMedia';

type validateStatesOfAccountDistributionPartnerEdit =
  | typeof BILLING_ADDRESS_NAME
  | typeof BILLING_ADDRESS_DIVISION
  | typeof BILLING_ADDRESS_POSTAL_CODE
  | typeof BILLING_ADDRESS_PREFECTURE
  | typeof BILLING_ADDRESS_CITY
  | typeof BILLING_ADDRESS_ADDRESS;

type validateStatesOfAccountDistributionPartnerUserEdit =
  | typeof DISTRIBUTION_PARTNER_USER_NAME
  | typeof DISTRIBUTION_PARTNER_USER_DIVISION
  | typeof DISTRIBUTION_PARTNER_USER_PHONE_NUMBER;

const PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY =
  'projectAddingBulkGiftCardIssueConfigs';
const PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_ERROR_KEY =
  'projectAddingBulkGiftCardIssueConfigsTotal';

export const PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS = {
  REQUESTED_ISSUE_AMOUNT: 'requestedIssueAmount',
  DESIRED_DELIVERY_DATE: 'desiredDeliveryDate'
} as const;

export type PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEY_TYPES =
  (typeof PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS)[keyof typeof PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEYS];
type ProjectAddingBulkGiftCardIssueConfigError = {
  [key in PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_ERROR_KEY_TYPES]?: ErrorMessage;
};
const projectAddingBulkGiftCardIssueConfigInitialError = {};

const PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY =
  'projectAddingBulkGifteeBoxIssueConfigs';
const PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_ERROR_KEY =
  'projectAddingBulkGifteeBoxIssueConfigsTotal';

export const PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS = {
  REQUESTED_ISSUE_AMOUNT: 'requestedIssueAmount',
  DELIVERY_DATE: 'deliveryDate',
  INITIAL_POINT: 'intialPoint'
} as const;

export type PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEY_TYPES =
  (typeof PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS)[keyof typeof PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEYS];

type ProjectAddingBulkGifteeBoxIssueConfigError = {
  [key in PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_ERROR_KEY_TYPES]?: ErrorMessage;
};

const projectAddingBulkGifteeBoxIssueConfigInitialError = {};

export type Errors = {
  [PROJECT_DRAFT_CAMPAIGN_OVERVIEW_ERROR_GROUP]: {
    [key in projectDraftCampaignOverviewErrorKeys]?: ErrorMessage;
  };
  projectDraftGiftDistribution: {
    [key in validateStatesOfProjectDraftGifDistribution]?: ErrorMessage;
  };
  projectDraftGiftConfigs: GiftConfigsError;
  projectDraftGiftConfigsCommon: ErrorMessage;
  accountDistributionPartnerEdit: {
    [key in validateStatesOfAccountDistributionPartnerEdit]?: ErrorMessage;
  };
  accountDistributionPartnerUserEdit: {
    [key in validateStatesOfAccountDistributionPartnerUserEdit]?: ErrorMessage;
  };
  [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]: ProjectAddingBulkGiftCardIssueConfigError[];
  [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: ErrorMessage;
  [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]: ProjectAddingBulkGifteeBoxIssueConfigError[];
  [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: ErrorMessage;
} & CommonErrors;

export type GiftConfigsError = {
  [key: number]: GiftConfigError;
};

// TODO: キーの追加
export type GiftConfigError = {
  selectedContents?: ErrorMessage;
  selectedGifteeBoxTemplate?: ErrorMessage;
  issueAmount?: ErrorMessage;
  deliveryDate?: ErrorMessage;
  gifteeBoxInitialPoint?: ErrorMessage;
  gifteeBoxIssueEstimateOptionInitialPoint?: ErrorMessage;
  gifteeBoxIssueEstimateOptionIssueAmount?: ErrorMessage;
  senderName?: ErrorMessage;
  message?: ErrorMessage;
};

export type CommonErrors = {
  common?: ErrorMessage[];
};

export const initialErrors: Errors = {
  projectDraftCampaignOverview: {},
  projectDraftGiftDistribution: {},
  projectDraftGiftConfigs: {},
  projectDraftGiftConfigsCommon: '',
  accountDistributionPartnerEdit: {},
  accountDistributionPartnerUserEdit: {},
  [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]: [
    projectAddingBulkGiftCardIssueConfigInitialError
  ],
  [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: '',
  [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]: [
    projectAddingBulkGifteeBoxIssueConfigInitialError
  ],
  [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: '',
  common: []
};

export const errors = (state = initialErrors, action: AppAction): Errors => {
  switch (action.type) {
    case AppActionTypes.VALIDATE_FAILURE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.error
        }
      };
    case AppActionTypes.VALIDATE_SUCCESS:
      const nextState = { ...state };
      delete nextState[action.category][action.errorKey];
      return nextState;
    case AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_SUCCESS:
      return {
        ...state,
        projectDraftGiftConfigsCommon: ''
      };
    case AppActionTypes.VALIDATION_GIFT_CONFIG_COMMON_FAILURE:
      return {
        ...state,
        projectDraftGiftConfigsCommon: action.errorMessage
      };
    case AppActionTypes.VALIDATION_GIFT_CONFIG_SUCCESS:
      const targetGiftConfig = state.projectDraftGiftConfigs[action.index];
      if (!targetGiftConfig) {
        return { ...state };
      }

      delete targetGiftConfig[action.key];
      if (Object.keys(targetGiftConfig).length === 0) {
        const nextState: Errors = { ...state };
        delete nextState['projectDraftGiftConfigs'][action.index];
        return nextState;
      }
      return {
        ...state,
        projectDraftGiftConfigs: {
          ...state.projectDraftGiftConfigs,
          [action.index]: targetGiftConfig
        }
      };
    case AppActionTypes.VALIDATION_GIFT_CONFIG_FAILURE:
      return {
        ...state,
        projectDraftGiftConfigs: {
          ...state.projectDraftGiftConfigs,
          [action.index]: {
            ...state.projectDraftGiftConfigs[action.index],
            [action.key]: action.errorMessage
          }
        }
      };
    case AppActionTypes.MUTATION_FAILURE:
    case AppActionTypes.GRAPHQL_FAILURE:
      return {
        ...state,
        common: action.errors
      };
    case AppActionTypes.ADD_NEW_GIFT_CONFIG:
    case AppActionTypes.DELETE_GIFT_CONFIG:
      return {
        ...state,
        projectDraftGiftConfigs: initialErrors.projectDraftGiftConfigs
      };
    case AppActionTypes.UPDATE_HOST_COMPANY_RELATION_TO_DP:
      if (action.relationToDP === RELATION_BETWEEN_HOST_COMPANY_AND_DP.EQUAL) {
        const nextState = { ...state };
        delete nextState.projectDraftCampaignOverview.hostCompany;
        return nextState;
      }
      return state;

    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS: {
      const targetBulkGiftCardIssueConfig =
        state[PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY][
          action.index
        ];
      if (!targetBulkGiftCardIssueConfig) {
        return state;
      }

      delete targetBulkGiftCardIssueConfig[action.key];
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGiftCardIssueConfigs.map(
            (projectAddingBulkGiftCardIssueConfigError, index) => {
              if (index === action.index) {
                return targetBulkGiftCardIssueConfig;
              }
              return projectAddingBulkGiftCardIssueConfigError;
            }
          )
      };
    }
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGiftCardIssueConfigs.map(
            (projectAddingBulkGiftCardIssueConfigError, index) => {
              if (index === action.index) {
                return {
                  ...projectAddingBulkGiftCardIssueConfigError,
                  [action.key]: action.errorMessage
                };
              }
              return projectAddingBulkGiftCardIssueConfigError;
            }
          )
      };
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_SUCCESS:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: ''
      };
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_FAILURE:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_ERROR_KEY]:
          action.errorMessage
      };
    case AppActionTypes.ADD_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]: [
          ...state.projectAddingBulkGiftCardIssueConfigs,
          projectAddingBulkGiftCardIssueConfigInitialError
        ]
      };
    case AppActionTypes.REMOVE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGiftCardIssueConfigs.filter(
            (_, index) => index !== action.addingBulkGiftCardIssueConfigIndex
          )
      };

    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS: {
      const targetBulkGifteeBoxIssueConfig =
        state[PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY][
          action.index
        ];
      if (!targetBulkGifteeBoxIssueConfig) {
        return state;
      }

      delete targetBulkGifteeBoxIssueConfig[action.key];
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGifteeBoxIssueConfigs.map(
            (projectAddingBulkGifteeBoxIssueConfigError, index) => {
              if (index === action.index) {
                return targetBulkGifteeBoxIssueConfig;
              }
              return projectAddingBulkGifteeBoxIssueConfigError;
            }
          )
      };
    }
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGifteeBoxIssueConfigs.map(
            (projectAddingBulkGifteeBoxIssueConfigError, index) => {
              if (index === action.index) {
                return {
                  ...projectAddingBulkGifteeBoxIssueConfigError,
                  [action.key]: action.errorMessage
                };
              }
              return projectAddingBulkGifteeBoxIssueConfigError;
            }
          )
      };
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_SUCCESS:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_ERROR_KEY]: ''
      };
    case AppActionTypes.VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_FAILURE:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_ERROR_KEY]:
          action.errorMessage
      };
    case AppActionTypes.ADD_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]: [
          ...state.projectAddingBulkGifteeBoxIssueConfigs,
          projectAddingBulkGifteeBoxIssueConfigInitialError
        ]
      };
    case AppActionTypes.REMOVE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG:
      return {
        ...state,
        [PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_ERROR_KEY]:
          state.projectAddingBulkGifteeBoxIssueConfigs.filter(
            (_, index) => index !== action.addingBulkGifteeBoxIssueConfigIndex
          )
      };
    case AppActionTypes.SELECT_PROJECT_DRAFT:
    case AppActionTypes.REGISTER_PROJECT_DRAFT_START:
    case AppActionTypes.REQUEST_GIFT_CARD_ISSUE_AMOUNT_START:
    case AppActionTypes.CREATE_PROJECT_START:
    case AppActionTypes.INITIALIZE_PROJECT_DRAFT_SYNC_STATUS:
    case AppActionTypes.UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS:
    case AppActionTypes.UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS:
    case AppActionTypes.OPEN_DISTRIBUTION_PARTNER_EDIT:
    case AppActionTypes.UPDATE_API_ALLOWED_IP_START:
    case AppActionTypes.ADD_API_ALLOWED_IP_START:
    case AppActionTypes.EXIT_API_ALLOWED_IP_ADD_MODAL:
    case AppActionTypes.ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_START:
    case AppActionTypes.ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_START:
    case AppActionTypes.INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW:
    case AppActionTypes.INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW:
      return initialErrors;
    default:
      return state;
  }
};
