import campaignSolutionSystemGcpSelected from '@console/reducers/projectJson/campaignSolutionSystemGcpSelected';
import { hostCompany } from '@console/reducers/projectJson/hostCompany';
import { combineReducers } from 'redux';
import { AppActionTypes } from '../../actions/actionType';
import { advertisingPeriod } from './advertisingPeriod';
import { campaignAdvertisingMedia } from './campaignAdvertisingMedia';
import { campaignGiftDistributionChannelDescriptions } from './campaignGiftDistributionChannelDescriptions';
import { campaignGiftDistributionChannels } from './campaignGiftDistributionChannels';
import campaignMarketingIndustry from './campaignMarketingIndustry';
import campaignSolutionSystem from './campaignSolutionSystem';
import { claimEstimate } from './claimEstimate';
import { giftConfigs } from './giftConfigs';
import { giftDistributionPeriod } from './giftDistributionPeriod';
import { projectPurposeCategory } from './projectPurposeCategory';
import { selectedGiftConfigIndex } from './selectedGiftConfigIndex';
import { thirdPartyService } from './thirdPartyService';
import type { AppAction } from '../../actions/actionType';

export type Name = string;
export const initialStateOfName: Name = '';

export type Description = string;
export const initialStateOfDescription: Description = '';

const name = (state = initialStateOfName, action: AppAction): Name => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfName;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.name;
    case AppActionTypes.UPDATE_CAMPAIGN_NAME:
      return action.campaignName;
    default:
      return state;
  }
};

const description = (
  state = initialStateOfDescription,
  action: AppAction
): Description => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE:
      return initialStateOfDescription;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.projectJson.description;
    case AppActionTypes.UPDATE_CAMPAIGN_DESCRIPTION:
      return action.campaignDescription;
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return action.project.description;
    default:
      return state;
  }
};

const projectJson = combineReducers({
  name,
  description,
  hostCompany,
  advertisingPeriod,
  giftDistributionPeriod,
  campaignSolutionSystem,
  campaignSolutionSystemGcpSelected,
  campaignMarketingIndustry,
  projectPurposeCategory,
  thirdPartyService,
  giftConfigs,
  campaignGiftDistributionChannels,
  campaignGiftDistributionChannelDescriptions,
  campaignAdvertisingMedia,
  claimEstimate,
  selectedGiftConfigIndex
});

export default projectJson;
