import { QueryGiftUsageReviewStatusTypes } from '@common/api/graphql/getProjectsGql';
import { createSelector } from 'reselect';
import type { GifteeBoxTemplateInitialPointCandidate } from '@common/api/graphql/getGifteeBoxTemplatesGql';
import type { AppState } from '@console/reducers';
import type {
  LocalGifteeBoxConfig,
  LocalProject
} from '@console/reducers/projects';

const getProjectDetailGifteeBoxConfigs = (state: AppState) =>
  state.projectDetail.gifteeBoxConfigs;

export const getGifteeBoxConfigs = createSelector(
  [getProjectDetailGifteeBoxConfigs],
  (gifteeBoxConfigs): NonNullable<LocalProject['gifteeBoxConfigs']> =>
    gifteeBoxConfigs ?? []
);

const getSelectedProjectGifteeBoxConfigUrlCode = (state: AppState) =>
  state.selectedProjectGifteeBoxConfigUrlCode;

export const getSelectedGifteeBoxConfig = createSelector(
  [getGifteeBoxConfigs, getSelectedProjectGifteeBoxConfigUrlCode],
  (
    gifteeBoxConfigs,
    gifteeBoxConfigUrlCode
  ): LocalGifteeBoxConfig | undefined =>
    gifteeBoxConfigs.find(
      gifteeBoxConfig => gifteeBoxConfig.urlCode === gifteeBoxConfigUrlCode
    )
);

export const getSelectedGifteeBoxTemplate = createSelector(
  [getSelectedGifteeBoxConfig],
  (
    selectedGifteeBoxConfig
  ): LocalGifteeBoxConfig['gifteeBoxTemplate'] | undefined =>
    selectedGifteeBoxConfig?.gifteeBoxTemplate
);

export const getSelectedInitialPointCandidates = createSelector(
  [getSelectedGifteeBoxTemplate],
  (
    selectedGifteeBoxTemplate
  ): GifteeBoxTemplateInitialPointCandidate['initialPoint'][] =>
    selectedGifteeBoxTemplate
      ? selectedGifteeBoxTemplate.initialPointCandidates.map(
          initialPointCandidate => initialPointCandidate.initialPoint
        )
      : []
);

export const isGiftUsageReviewStatusApproved = (
  giftUsageReviewStatus: string
): boolean =>
  giftUsageReviewStatus === QueryGiftUsageReviewStatusTypes.APPROVED;
