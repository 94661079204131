import CommonErrorContainer from '@console/containers/common/CommonErrorContainer';
import Add from '@mui/icons-material/Add';
import Security from '@mui/icons-material/Security';
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, Fragment } from 'react';
import AddModal from './AddModal';
import APIIpRestrictionExample from './Example';
import Modal from './Modal';
import type { QueryApiAllowedIp } from '@common/api/graphql/getProjectsGql';
import type {
  TablePropsMappedFromState,
  TablePropsMappedFromDispatch
} from '@console/containers/project/apiIpRestriction/TableContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableContainer: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(5)
  },
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(0.5),
    width: '50%',
    marginBottom: theme.spacing(2)
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  button: {
    padding: `${theme.spacing()} ${theme.spacing(3)}`
  },
  divider: {
    margin: `0 ${theme.spacing(2)}`
  },
  example: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  errorMessage: {
    minHeight: theme.spacing(3),
    fontSize: 14
  },
  empty: {
    textAlign: 'center',
    marginBottom: theme.spacing(10)
  },
  securityIcon: {
    margin: theme.spacing(5),
    color: theme.palette.icon.file
  }
});

type APIIpRestrictionTableProps = TablePropsMappedFromState &
  TablePropsMappedFromDispatch &
  WithStyles<typeof styles>;

const APIIpRestrictionTable: React.FC<APIIpRestrictionTableProps> = ({
  classes,
  apiAllowedIps,
  onChangeApiAllowedIpAddress,
  updateApiAllowedIp,
  deleteApiAllowedIp,
  addApiAllowedIp,
  onExitAddModal
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedApiAllowedIp, setSelectedApiAllowedIp] =
    useState<QueryApiAllowedIp | null>(null);

  const handleUpdateClick = (apiAllowedIp: QueryApiAllowedIp): void => {
    setSelectedApiAllowedIp(apiAllowedIp);
    setIsUpdate(true);
    openModal();
  };

  const handleDeleteClick = (apiAllowedIp: QueryApiAllowedIp): void => {
    setSelectedApiAllowedIp(apiAllowedIp);
    setIsUpdate(false);
    openModal();
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setSelectedApiAllowedIp(null);
  };

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  const handleOKClick = (): void => {
    if (!selectedApiAllowedIp) return;
    if (isUpdate) {
      updateApiAllowedIp(selectedApiAllowedIp);
    } else {
      deleteApiAllowedIp(selectedApiAllowedIp.urlCode);
    }
    setIsModalOpen(false);
  };

  const handleAddClick = (): void => {
    openAddModal();
  };

  const closeAddModal = (): void => {
    setIsAddModalOpen(false);
  };

  const openAddModal = (): void => {
    setIsAddModalOpen(true);
  };

  const handleAddModalOKClick = (ip: QueryApiAllowedIp['ip']) => {
    addApiAllowedIp(ip)
      .then(() => {
        closeAddModal();
      })
      .catch(() => {});
  };

  const handleAddModalOnExit = (): void => {
    onExitAddModal();
  };

  return (
    <div className={classes.tableContainer} data-cy="tableContainer">
      <Modal
        open={isModalOpen}
        closeModal={closeModal}
        selectedApiAllowedIp={selectedApiAllowedIp}
        isUpdate={isUpdate}
        handleOKClick={handleOKClick}
      />
      {isAddModalOpen ? null : <CommonErrorContainer />}
      <AddModal
        open={isAddModalOpen}
        closeModal={closeAddModal}
        handleOKClick={handleAddModalOKClick}
        handleOnExit={handleAddModalOnExit}
      />

      {apiAllowedIps.length === 0 ? (
        <div className={classes.empty}>
          <Security className={classes.securityIcon} fontSize="large" />
          <Typography variant="subtitle1" color="textSecondary">
            許可するIPアドレスを設定していません。未設定の場合、全ての通信を遮断する設定になっております。
          </Typography>
        </div>
      ) : (
        <Fragment>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  許可済みIPアドレス
                </TableCell>
                <TableCell className={classes.tableCell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiAllowedIps.map((apiAllowedIp, apiAllowedIpIndex) => (
                <TableRow key={apiAllowedIp.urlCode}>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      variant="standard"
                      value={apiAllowedIp.ip}
                      fullWidth
                      onChange={event =>
                        onChangeApiAllowedIpAddress(event, apiAllowedIp.urlCode)
                      }
                      data-cy={`apiAllowedIpAddress-${apiAllowedIpIndex}`}
                      inputProps={{ maxLength: 50 }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        data-cy={`apiAllowedIpUpdateButton-${apiAllowedIpIndex}`}
                        onClick={() => handleUpdateClick(apiAllowedIp)}
                      >
                        <Typography variant="body2">更新</Typography>
                      </Button>
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.divider}
                      />
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.button}
                        data-cy={`apiAllowedIpRemoveButton-${apiAllowedIpIndex}`}
                        onClick={() => handleDeleteClick(apiAllowedIp)}
                      >
                        <Typography variant="body2">削除</Typography>
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.example}>
            <APIIpRestrictionExample />
          </div>
        </Fragment>
      )}

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Add />}
        onClick={handleAddClick}
        data-cy="apiAllowedIpAddButton"
      >
        許可するIPアドレスを追加
      </Button>
    </div>
  );
};

export default withStyles(styles)(APIIpRestrictionTable);
