import {
  getLocaleDateString,
  getLocaleEndDateString
} from '@common/lib/getLocaleDate';
import ReviewRequestMessage from '@console/containers/project/ReviewRequestMessageContainer';
import { Typography, Table, TableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DetailTableRow from '../common/DetailTableRow';
import type {
  DetailPropsMappedFromState,
  DetailPropsMappedFromDispatch
} from '../../containers/project/DetailContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  sections: {
    paddingBottom: theme.spacing(5)
  },
  section: {
    paddingTop: theme.spacing(4)
  },
  sectionTitle: {
    paddingBottom: theme.spacing(1)
  },
  table: {
    width: '70%'
  }
});

type DetailProps = DetailPropsMappedFromState &
  DetailPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const Detail: React.FC<DetailProps> = ({
  projectGiftDistributionChannelsForDisplay,
  projectAdvertisingMediumRegistrationsForDisplay,
  selectedProject,
  classes
}) => (
  <div className={classes.sections}>
    <ReviewRequestMessage />
    <section className={classes.section}>
      <Typography variant="subtitle2" className={classes.sectionTitle}>
        キャンペーン主催企業
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <DetailTableRow
            label="企業名"
            value={selectedProject.hostCompany.name}
          />
          {/* <DetailTableRow
              label="住所"
              value={dummyProject.detail.distributionPartner.address}
            />
            <DetailTableRow
              label="部署名"
              value={dummyProject.detail.distributionPartner.division}
            />
            <DetailTableRow
              label="電話番号"
              value={dummyProject.detail.distributionPartner.phoneNumber}
            />
            <DetailTableRow
              label="担当者"
              value={dummyProject.detail.distributionPartner.name}
            /> */}
        </TableBody>
      </Table>
    </section>
    {/* <section className={classes.section}>
        <Typography variant="subtitle2" className={classes.sectionTitle}>
          代理店情報
        </Typography>
        <Table className={classes.table}>
          <TableBody>
            <DetailTableRow
              label="企業名"
              value={dummyProject.detail.agent.companyName}
            />
            <DetailTableRow
              label="住所"
              value={dummyProject.detail.agent.address}
            />
            <DetailTableRow
              label="部署名"
              value={dummyProject.detail.agent.division}
            />
            <DetailTableRow
              label="電話番号"
              value={dummyProject.detail.agent.phoneNumber}
            />
            <DetailTableRow
              label="担当者"
              value={dummyProject.detail.agent.name}
            />
          </TableBody>
        </Table>
      </section> */}
    <section className={classes.section}>
      <Typography variant="subtitle2" className={classes.sectionTitle}>
        キャンペーン情報
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <DetailTableRow
            label="利用サービス"
            value={selectedProject.solutionSystem.name}
          />
          <DetailTableRow label="キャンペーン名" value={selectedProject.name} />
          <DetailTableRow
            label="キャンペーン概要"
            value={selectedProject.description}
          />
          <DetailTableRow
            label="キャンペーン実施業界"
            value={selectedProject.marketingIndustry.name}
          />
          <DetailTableRow
            label="用途"
            value={selectedProject.projectPurposeCategory.name}
          />
          {selectedProject.advertisingPeriod ? (
            <DetailTableRow
              label="掲載期間"
              value={`${getLocaleDateString(
                selectedProject.advertisingPeriod.beginAt
              )} ~ ${getLocaleDateString(
                selectedProject.advertisingPeriod.endAt
              )}`}
            />
          ) : null}
          {selectedProject.giftDistributionPeriod ? (
            <DetailTableRow
              label="ギフト配布期間"
              value={`${getLocaleDateString(
                selectedProject.giftDistributionPeriod.beginAt
              )} ~ ${getLocaleEndDateString(
                selectedProject.giftDistributionPeriod.endAt
              )}`}
            />
          ) : null}

          <DetailTableRow
            label="キャンペーン対象のサービス名"
            value={selectedProject.thirdPartyService?.name ?? ''}
          />
          <DetailTableRow
            label="キャンペーン対象のサービスサイトURL"
            value={selectedProject.thirdPartyService?.url ?? ''}
          />
          <DetailTableRow
            label="キャンペーン対象のサービス内容"
            value={selectedProject.thirdPartyService?.description ?? ''}
          />
          <DetailTableRow
            label="ギフトの配布方法"
            value={projectGiftDistributionChannelsForDisplay}
          />
          <DetailTableRow
            label="露出媒体"
            value={projectAdvertisingMediumRegistrationsForDisplay}
          />
        </TableBody>
      </Table>
    </section>
  </div>
);

export default withStyles(styles)(Detail);
