import { isAPIConfidentialInformation } from '@console/common/project/confidentialInformation';
import GifteeBoxTemplateContentSelectionsModalContainer from '@console/containers/common/GifteeBoxTemplateContentSelectionsModalContainer';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import Notes from '../Notes';
import GifteeBoxConfigRow from './GifteeBoxConfigRow';
import type {
  GifteeBoxConfigsTablePropsMappedFromDispatch,
  GifteeBoxConfigsTablePropsMappedFromState
} from '@console/containers/project/giftConfig/APITable/GifteeBoxConfigsTableContainer';
import type {
  LocalGifteeBoxConfig,
  LocalAPIConfidentialInformationGifteeBoxConfig
} from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  table: {
    textAlign: 'left',
    marginTop: theme.spacing(4)
  },
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  notes: {
    marginTop: theme.spacing(2)
  }
});

type GifteeBoxConfigsTableProps = GifteeBoxConfigsTablePropsMappedFromDispatch &
  GifteeBoxConfigsTablePropsMappedFromState &
  WithStyles<typeof styles>;

const NOTES = [
  '※ giftee_box_config_code は、ご利用の準備が整い次第発行されます。'
];

const GifteeBoxConfigsTable: React.FC<GifteeBoxConfigsTableProps> = ({
  classes,
  isOldReviewProcess,
  gifteeBoxConfigs,
  confidentialInformation,
  selectGifteeBoxTemplate,
  deselectGifteeBoxTemplate
}) => {
  const [
    gifteeBoxTemplateContentSelectionsModalOpen,
    setGifteeBoxTemplateContentSelectionsModalOpen
  ] = useState(false);

  const openGifteeBoxTemplateContentSelectionsModal = (
    gifteeBoxConfig: LocalGifteeBoxConfig
  ) => {
    selectGifteeBoxTemplate(gifteeBoxConfig.gifteeBoxTemplate);
    setGifteeBoxTemplateContentSelectionsModalOpen(true);
  };

  const closeGifteeBoxTemplateContentSelectionsModal = () => {
    setGifteeBoxTemplateContentSelectionsModalOpen(false);
    deselectGifteeBoxTemplate();
  };

  const getConfidentialInformationGifteeBoxConfig = (
    gifteeBoxConfigUrlCode: string
  ): LocalAPIConfidentialInformationGifteeBoxConfig | null | undefined => {
    if (isAPIConfidentialInformation(confidentialInformation)) {
      return confidentialInformation.gifteeBoxConfigs.find(
        gifteBoxConfig => gifteeBoxConfigUrlCode === gifteBoxConfig.urlCode
      );
    }
    return null;
  };

  return (
    <>
      <Table className={classes.table} data-cy="gifteeBoxConfigsTable">
        <TableHead data-cy="gifteeBoxConfigsTableHeader">
          <TableRow>
            {isOldReviewProcess ? null : (
              <TableCell className={classes.tableCell} align="center">
                審査ステータス
              </TableCell>
            )}
            <TableCell className={classes.tableCell} align="center">
              ギフトのタイプ
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              ギフト・ブランド名
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              giftee_box_config_code
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              発行開始日
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              発行終了日
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gifteeBoxConfigs?.map((gifteeBoxConfig, gifteeBoxConfigIndex) => (
            <GifteeBoxConfigRow
              key={gifteeBoxConfig.urlCode}
              isOldReviewProcess={!!isOldReviewProcess}
              gifteeBoxConfig={gifteeBoxConfig}
              confidentialInformationGifteeBoxConfig={getConfidentialInformationGifteeBoxConfig(
                gifteeBoxConfig.urlCode
              )}
              handleGifteeBoxTemplateContentSelectionsModalOpen={
                openGifteeBoxTemplateContentSelectionsModal
              }
            />
          ))}
        </TableBody>
      </Table>
      <div className={classes.notes}>
        <Notes notes={NOTES} />
      </div>
      <GifteeBoxTemplateContentSelectionsModalContainer
        isOpen={gifteeBoxTemplateContentSelectionsModalOpen}
        handleClose={closeGifteeBoxTemplateContentSelectionsModal}
      />
    </>
  );
};

export default withStyles(styles)(GifteeBoxConfigsTable);
