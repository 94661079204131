import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { FRAGMENT_SOLUTION_SYSTEM } from './fragments/solutionSystem';
import { operationNames } from './index';
import type { FetchResult } from '@apollo/client';

const getSolutionSystemsGql = gql`
  ${FRAGMENT_SOLUTION_SYSTEM}
  query ${operationNames.GET_SOLUTION_SYSTEMS}{
    solutionSystems {
      ...SolutionSystemFields
    }
  }
`;

export type SolutionSystem = {
  name: string;
  solutionSystemIdentifier: string;
  urlCode: string;
};

export type SolutionSystems = SolutionSystem[];

export type GetSolutionSystemsResponse = FetchResult<{
  solutionSystems: SolutionSystems;
}>;

export const getSolutionSystems = (): Promise<GetSolutionSystemsResponse> =>
  internalApiAppClient.query({
    query: getSolutionSystemsGql
  });
