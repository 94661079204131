import { QueryGiftUsageReviewStatusTypes } from '@common/api/graphql/getProjectsGql';
import { Chip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import type { ClassNameMap, Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    chip: {
      borderRadius: 5
    },
    requested: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white
    },
    approved: {
      backgroundColor: theme.palette.success.main
    },
    rejected: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.common.white
    }
  });

const getGiftUsageStatusLabel = (status: string): string => {
  switch (status) {
    case QueryGiftUsageReviewStatusTypes.NOT_REQUESTED:
      return '申請前';
    case QueryGiftUsageReviewStatusTypes.REQUESTED:
      return '審査中';
    case QueryGiftUsageReviewStatusTypes.APPROVED:
      return '承認';
    case QueryGiftUsageReviewStatusTypes.REJECTED:
      return '棄却';
    case QueryGiftUsageReviewStatusTypes.EXEMPTED:
      return '審査免除';
    default:
      return '';
  }
};

const getGiftUsageStatusClass = (
  classes: ClassNameMap<string>,
  status: string
): string => {
  switch (status) {
    case QueryGiftUsageReviewStatusTypes.APPROVED:
    case QueryGiftUsageReviewStatusTypes.EXEMPTED:
      return classes.approved;
    case QueryGiftUsageReviewStatusTypes.REJECTED:
      return classes.rejected;
    case QueryGiftUsageReviewStatusTypes.REQUESTED:
      return classes.requested;
    case QueryGiftUsageReviewStatusTypes.NOT_REQUESTED:
    default:
      return '';
  }
};

type GiftUsageReviewStatusChipOwnProps = {
  status: string;
};

type GiftUsageReviewStatusChipProps = GiftUsageReviewStatusChipOwnProps &
  WithStyles<typeof styles>;

const GiftUsageReviewStatusChip: React.FC<GiftUsageReviewStatusChipProps> = ({
  classes,
  status
}) => (
  <Chip
    label={getGiftUsageStatusLabel(status)}
    className={clsx(classes.chip, getGiftUsageStatusClass(classes, status))}
    data-cy="GiftUsageStatusChip"
  />
);

export default withStyles(styles)(GiftUsageReviewStatusChip);
