import DefaultFilePreview from '@console/components/project/messageThread/messageThreadDialog/DefaultFilePreview';
import ImageFilePreview from '@console/components/project/messageThread/messageThreadDialog/ImageFilePreview';
import AttachFile from '@mui/icons-material/AttachFile';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
  IconButton,
  InputBase,
  Grid
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PdfFilePreview from './messageThreadDialog/PdfFilePreview';
import type {
  MessageThreadDialogPropsMappedFromState,
  MessageThreadDialogPropsMappedFromDispatch
} from '@console/containers/project/MessageThreadDialogContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  attachFileButtonContainer: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    display: 'table-cell',
    padding: `${theme.spacing(1)}`,
    textAlign: 'center',
    verticalAlign: 'bottom',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'pointer'
    }
  },
  messageForm: {
    width: '100%',
    padding: `0 ${theme.spacing(1)}`
  },
  inputBox: {
    width: '100%'
  },
  messageInput: {
    display: 'table-cell',
    padding: theme.spacing(1),
    width: '100%'
  },
  submitButton: {
    height: 'inherit',
    display: 'block',
    marginTop: theme.spacing(1),
    marginLeft: 'auto'
  },
  filePreviewListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    margin: `${theme.spacing(2)} 0`
  },
  filePreviewList: {
    flexWrap: 'nowrap',
    width: '100%'
  },
  imgPreview: {
    width: '100%'
  },
  pdfPreview: {
    height: '100%',
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)'
  },
  defaultFile: {
    position: 'absolute',
    width: '40%',
    height: '40%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
});

type MessageThreadDialogProps = MessageThreadDialogPropsMappedFromState &
  MessageThreadDialogPropsMappedFromDispatch &
  WithStyles<typeof styles>;

const MessageThreadDialog: React.FC<MessageThreadDialogProps> = ({
  attachFile,
  handleChangeMessageThreadInputMessage,
  handleDropFiles,
  handleSubmitButton,
  removeAttachedFile,
  removeAttachedFileAll,
  messageThreadUrlCode,
  messageThreadInputText,
  messageThreadAttachedFiles,
  isMessageThreadDialogOpen,
  classes
}) => (
  <Dialog
    open={isMessageThreadDialogOpen}
    onClose={removeAttachedFileAll}
    data-cy="messageThreadDialog"
    onDragOver={event => event.preventDefault()}
    onDrop={handleDropFiles}
    maxWidth="sm"
    fullWidth
  >
    <DialogContent>
      <div className={classes.messageForm}>
        <Box
          border={1}
          borderColor="grey.500"
          borderRadius={1}
          overflow="hidden"
          className={classes.inputBox}
        >
          <input
            style={{ display: 'none' }}
            id="attachFileButton"
            type="file"
            onChange={attachFile}
            data-cy="attachFile"
          />
          <label
            htmlFor="attachFileButton"
            className={classes.attachFileButtonContainer}
          >
            <IconButton aria-label="Menu" component="span" size="large">
              <AttachFile color="primary" />
            </IconButton>
          </label>
          <InputBase
            placeholder="メッセージを入力してください。"
            multiline
            autoFocus
            minRows={1}
            maxRows={13}
            value={messageThreadInputText}
            onChange={handleChangeMessageThreadInputMessage}
            data-cy="messageInputTextField"
            className={classes.messageInput}
          />
        </Box>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !(
              messageThreadInputText?.trim().length ||
              messageThreadAttachedFiles.length
            )
          }
          onClick={() => handleSubmitButton(messageThreadUrlCode)}
          data-cy="submitMessageAndAttachedFiles"
          className={classes.submitButton}
        >
          送信
        </Button>
      </div>
      {messageThreadAttachedFiles.length > 0 && (
        <div className={classes.filePreviewListContainer}>
          <ImageList className={classes.filePreviewList} cols={2.5} gap={24}>
            {messageThreadAttachedFiles.map((attachedFile, index) => {
              switch (attachedFile.type) {
                case 'image/gif':
                case 'image/jpeg':
                case 'image/png':
                  return (
                    <ImageListItem
                      key={`${attachedFile.name}-${index}`}
                      data-cy="dialogImgPreview"
                    >
                      <ImageFilePreview
                        attachedFile={attachedFile}
                        removeAttachedFile={removeAttachedFile.bind(
                          this,
                          index
                        )}
                        classes={{
                          imgPreview: classes.imgPreview
                        }}
                      />
                    </ImageListItem>
                  );
                case 'application/pdf':
                  return (
                    <ImageListItem
                      key={`${attachedFile.name}-${index}`}
                      data-cy="dialogPdfPreview"
                    >
                      <PdfFilePreview
                        attachedFile={attachedFile}
                        removeAttachedFile={removeAttachedFile.bind(
                          this,
                          index
                        )}
                      />
                    </ImageListItem>
                  );
                default:
                  return (
                    <ImageListItem
                      key={`${attachedFile.name}-${index}`}
                      data-cy="dialogDefaultPreview"
                    >
                      <DefaultFilePreview
                        attachedFile={attachedFile}
                        removeAttachedFile={removeAttachedFile.bind(
                          this,
                          index
                        )}
                      />
                    </ImageListItem>
                  );
              }
            })}
          </ImageList>
        </div>
      )}
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(MessageThreadDialog);
