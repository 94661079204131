import { getLocalString } from '@common/lib/getLocalString';
import { getLocalePrice } from '@common/lib/getLocalePrice';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import TicketTypeChip, {
  PROJECT_TICKET_TYPE
} from '@console/components/project/common/TicketTypeChip';
import ArchivedChip from '@console/components/project/giftConfig/ArchivedChip';
import GiftUsageReviewStatusChip from '@console/components/project/giftConfig/GiftUsageReviewStatusChip';
import { isGiftUsageReviewStatusApproved } from '@console/selectors/projectDetail/gifteeBoxConfigs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  TableRow,
  TableCell,
  Grid,
  Typography,
  TextField,
  Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import IssueAmountRequestModal from './IssueAmountRequestModal';
import type {
  GifteeBoxConfigRowPropsMappedFromDispatch,
  GifteeBoxConfigRowPropsMappedFromState
} from '@console/containers/project/giftConfig/bulkTable/GifteeBoxConfigRowContainer';
import type { LocalBulkGifteeBoxIssueConfig } from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  issueAmountContainer: {
    width: theme.spacing(12),
    whiteSpace: 'pre-line'
  },
  bulkGifteeBoxIssueConfigRow: {
    height: 100
  },
  textField: {
    minWidth: theme.spacing(10)
  },
  button: {
    margin: theme.spacing(),
    marginRight: 0,
    padding: '5px 8px'
  }
});

type GifteeBoxConfigRowProps = GifteeBoxConfigRowPropsMappedFromDispatch &
  GifteeBoxConfigRowPropsMappedFromState &
  WithStyles<typeof styles>;

const GifteeBoxConfigRow: React.FC<GifteeBoxConfigRowProps> = ({
  gifteeBoxConfig,
  isOldReviewProcess,
  selectedBulkGifteeBoxIssueConfig,
  onChangeBulkGifteeBoxIssueConfigIssueAmount,
  requestGifteeBoxIssueAmount,
  handleGifteeBoxTemplateContentSelectionsModalOpen,
  handleOpenGifteeBoxConfigMessageCardOptionModalOpen,
  goBulkGifteeBoxIssueConfigsNewPage,
  handleIssueAmountRequestClick,
  classes
}) => {
  const navigate = useNavigate();
  const [issueAmountRequestModalOpen, setIssueAmountRequestModalOpen] =
    useState(false);

  const toggleModalOpen = () => {
    setIssueAmountRequestModalOpen(!issueAmountRequestModalOpen);
  };

  const issueAmountRequestModalOKClick = () => {
    if (!!selectedBulkGifteeBoxIssueConfig) {
      requestGifteeBoxIssueAmount(
        selectedBulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount,
        selectedBulkGifteeBoxIssueConfig.urlCode
      );
    }
    setIssueAmountRequestModalOpen(false);
  };

  const exchangeableEndLabelString = (
    bulkGifteeBoxIssueConfigs: LocalBulkGifteeBoxIssueConfig[]
  ): string =>
    bulkGifteeBoxIssueConfigs.length !== 0
      ? bulkGifteeBoxIssueConfigs[bulkGifteeBoxIssueConfigs.length - 1]
          .gifteeBoxIssueConfig.exchangeableEndLabel
      : '';

  const giftUsageReviewStatusApproved = isGiftUsageReviewStatusApproved(
    gifteeBoxConfig.giftUsageReviewStatus
  );

  return (
    <>
      <IssueAmountRequestModal
        open={issueAmountRequestModalOpen}
        toggleModalOpen={toggleModalOpen}
        handleClick={issueAmountRequestModalOKClick}
        issueAmount={getLocalString(
          selectedBulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount
        )}
        currentPriceTotal={getLocalePrice(
          selectedBulkGifteeBoxIssueConfig.claimEstimate.estimatedAmount
        )}
        gifteeBoxConfig={gifteeBoxConfig}
        deliveryDate={selectedBulkGifteeBoxIssueConfig.deliveryDate}
      />
      {gifteeBoxConfig.bulkGifteeBoxIssueConfigs.map(
        (bulkGifteeBoxIssueConfig, bulkGifteeBoxIssueConfigIndex) => (
          <TableRow
            key={`bulkGifteeBoxIssueConfig-${bulkGifteeBoxIssueConfig.urlCode}`}
            className={classes.bulkGifteeBoxIssueConfigRow}
            data-cy={`bulkGifteeBoxIssueConfigRow-${bulkGifteeBoxIssueConfig.urlCode}`}
          >
            {bulkGifteeBoxIssueConfigIndex === 0 ? (
              <>
                {isOldReviewProcess ? null : (
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    rowSpan={gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length}
                    data-cy={`gifteeBoxConfigGiftUsageReviewStatus-${bulkGifteeBoxIssueConfig.urlCode}`}
                  >
                    {gifteeBoxConfig.archived ? (
                      <ArchivedChip />
                    ) : (
                      <GiftUsageReviewStatusChip
                        status={gifteeBoxConfig.giftUsageReviewStatus}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableCell}
                  align="center"
                  rowSpan={gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length}
                  data-cy={`bulkGifteeBoxIssueConfigTicketType-${bulkGifteeBoxIssueConfig.urlCode}`}
                >
                  <TicketTypeChip ticketType={PROJECT_TICKET_TYPE.GIFTEE_BOX} />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  rowSpan={gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length}
                  data-cy={`bulkGifteeBoxIssueConfigContent-${bulkGifteeBoxIssueConfig.urlCode}`}
                >
                  <GifteeBoxTemplateBrief
                    gifteeBoxTemplateName={
                      gifteeBoxConfig.gifteeBoxTemplate.name
                    }
                    gifteeBoxTemplateImageUrl={
                      gifteeBoxConfig.gifteeBoxTemplate.imageUrl
                    }
                    gifteeBoxConfigUrlCode={gifteeBoxConfig.urlCode}
                    handleButtonClick={
                      handleGifteeBoxTemplateContentSelectionsModalOpen
                    }
                    handleMessageCardOptionButton={
                      gifteeBoxConfig.messageCardOption
                        ? handleOpenGifteeBoxConfigMessageCardOptionModalOpen
                        : undefined
                    }
                    exchangeableEndLabel={exchangeableEndLabelString(
                      gifteeBoxConfig.bulkGifteeBoxIssueConfigs
                    )}
                    imageDownloadable={true}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  rowSpan={gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length}
                  data-cy={`bulkGifteeBoxIssueConfigPreview-${bulkGifteeBoxIssueConfig.urlCode}`}
                >
                  無し
                </TableCell>
              </>
            ) : null}

            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigInitialPoint-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {getLocalePrice(bulkGifteeBoxIssueConfig.initialPoint)}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigRequestedIssueAmount-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {getLocalString(bulkGifteeBoxIssueConfig.requestedIssueAmount)}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigRequestedAmount-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {getLocalePrice(
                bulkGifteeBoxIssueConfig.requestedIssueAmount *
                  bulkGifteeBoxIssueConfig.initialPoint
              )}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigFixedIssueAmount-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {bulkGifteeBoxIssueConfig.fixedIssueConfig ? (
                getLocalString(
                  bulkGifteeBoxIssueConfig.fixedIssueConfig.issueAmount
                )
              ) : bulkGifteeBoxIssueConfig.isIssueAmountRequested ? (
                getLocalString(
                  bulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount
                )
              ) : (
                <Grid
                  className={classes.issueAmountContainer}
                  container
                  justifyContent="flex-end"
                >
                  <TextField
                    variant="standard"
                    required
                    type="number"
                    value={
                      bulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount
                    }
                    inputProps={{ min: '1' }}
                    onChange={e =>
                      onChangeBulkGifteeBoxIssueConfigIssueAmount(
                        e,
                        gifteeBoxConfig.urlCode,
                        bulkGifteeBoxIssueConfig.urlCode
                      )
                    }
                    disabled={!giftUsageReviewStatusApproved}
                    className={classes.textField}
                    data-cy={`bulkGifteeBoxIssueConfigIssueAmountRequestText-${bulkGifteeBoxIssueConfig.urlCode}`}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      handleIssueAmountRequestClick(
                        gifteeBoxConfig,
                        bulkGifteeBoxIssueConfig,
                        () => setIssueAmountRequestModalOpen(true)
                      )
                    }
                    disabled={!giftUsageReviewStatusApproved}
                    className={classes.button}
                    data-cy={`bulkGifteeBoxIssueConfigIssueAmountRequestButton-${bulkGifteeBoxIssueConfig.urlCode}`}
                  >
                    <Typography variant="body2">数量確定</Typography>
                  </Button>
                </Grid>
              )}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigFixedAmount-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {bulkGifteeBoxIssueConfig.fixedIssueConfig
                ? getLocalePrice(
                    bulkGifteeBoxIssueConfig.fixedIssueConfig.issueAmount *
                      bulkGifteeBoxIssueConfig.initialPoint
                  )
                : bulkGifteeBoxIssueConfig.isIssueAmountRequested
                  ? getLocalePrice(
                      bulkGifteeBoxIssueConfig.issueAmountRequest.issueAmount *
                        bulkGifteeBoxIssueConfig.initialPoint
                    )
                  : null}
            </TableCell>
            <TableCell
              className={classes.tableCell}
              align="right"
              data-cy={`bulkGifteeBoxIssueConfigDeliveryDate-${bulkGifteeBoxIssueConfig.urlCode}`}
            >
              {bulkGifteeBoxIssueConfig.deliveryDate}
            </TableCell>
            {bulkGifteeBoxIssueConfigIndex === 0 ? (
              <TableCell
                className={classes.tableCell}
                align="center"
                rowSpan={gifteeBoxConfig.bulkGifteeBoxIssueConfigs.length}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    !giftUsageReviewStatusApproved || gifteeBoxConfig.archived
                  }
                  data-cy={`bulkGifteeBoxIssueConfigAddButton-${gifteeBoxConfig.urlCode}`}
                  onClick={() =>
                    goBulkGifteeBoxIssueConfigsNewPage(
                      navigate,
                      gifteeBoxConfig
                    )
                  }
                >
                  追加発注
                  <KeyboardArrowRightIcon />
                </Button>
              </TableCell>
            ) : null}
          </TableRow>
        )
      )}
    </>
  );
};

export default withStyles(styles)(GifteeBoxConfigRow);
