import { openProjectGiftCardPreview } from '@console/actions/actions';
import ApiTable from '@console/components/project/giftConfig/APITable';
import { getConfidentialInformation } from '@console/selectors/projectDetail/confidentialInformation';
import { getGiftCardConfigsForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';
import { connect } from 'react-redux';
import type { AppThunkDispatch } from '@console/actions/thunkType';
import type { AppState } from '@console/reducers';
import type { GiftCardConfigForDisplay } from '@console/selectors/projectDetail/giftCardConfigs';

const mapStateToProps = (state: AppState) => ({
  isOldReviewProcess: state.projectDetail.isOldReviewProcess,
  giftCardConfigs: getGiftCardConfigsForDisplay(state),
  confidentialInformation: getConfidentialInformation(state)
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  onClickGiftCardPreviewButton: (giftCardConfig: GiftCardConfigForDisplay) =>
    dispatch(openProjectGiftCardPreview(giftCardConfig.urlCode))
});

export type ApiTablePropsMappedFromState = ReturnType<typeof mapStateToProps>;
export type ApiTablePropsMappedFromDispatch = ReturnType<
  typeof mapDispatchToProps
>;

export default connect(mapStateToProps, mapDispatchToProps)(ApiTable);
