import { AppActionTypes } from '../actions/actionType';
import type { AppAction } from '../actions/actionType';
import type { ProjectPurposeCategories } from '@common/api/graphql/getProjectPurposeCategoriesGql';

const projectPurposeCategories = (
  state = [],
  action: AppAction
): ProjectPurposeCategories => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_PURPOSE_CATEGORIES_SUCCESS:
    case AppActionTypes.GET_MASTER_DATA_SUCCESS:
      return action.projectPurposeCategories;
    default:
      return state;
  }
};

export default projectPurposeCategories;
