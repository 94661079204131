import { hasGon } from '@common/lib/gon';

export const isGoogleTagManagerEnabled = <
  WindowType extends WindowWithGon,
  DataLayerType extends GoogleTagManagerDataLayer
>(
  window: WindowType
): window is WindowType & WindowWithGoogleTagManager<DataLayerType> =>
  !!window.gon.googleTagManagerEnable;

export const loadGoogleTagManager = (
  containerId: string,
  auth: string,
  preview: string
): void => {
  // https://developers.google.com/tag-manager/quickstart?hl=ja
  /* eslint-disable */
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    // @ts-ignore
    j.async = true;
    // @ts-ignore
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      `&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x`;
    // @ts-ignore
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', `${containerId}`);
  /* eslint-disable */
};

export const pushEvent = <
  EventParameterType extends GoogleTagManagerDataLayerItem
>(
  name: GoogleTagManagerEventName,
  params?: EventParameterType
): void => {
  if (hasGon(window) && isGoogleTagManagerEnabled(window)) {
    window.dataLayer.push({ ...(params ?? []), event: name });
  }
};
