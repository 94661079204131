import { getLocalePrice } from '@common/lib/getLocalePrice';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import GiftCardTicketConfigsContents from '@console/components/project/common/GiftCardTicketConfigsContents';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import type { GiftCardIssueReports } from '@console/selectors/projectDetail/giftCardIssueCounts';
import type { GifteeBoxIssueReports } from '@console/selectors/projectDetail/gifteeBoxIssueCounts';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    padding: theme.spacing(2)
  },
  footerTableCell: {
    padding: theme.spacing(2),
    borderBottom: 'none'
  }
});

type IssueReportsTableOwnProps = {
  giftCardIssueReports: GiftCardIssueReports;
  gifteeBoxIssueReports: GifteeBoxIssueReports;
};

type IssueReportsTableProps = IssueReportsTableOwnProps &
  WithStyles<typeof styles>;

const IssueReportsTable: React.FC<IssueReportsTableProps> = ({
  classes,
  giftCardIssueReports,
  gifteeBoxIssueReports
}) => (
  <Table data-cy="issueReportsTable">
    <TableHead>
      <TableRow data-cy="issueReportsTable-header">
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">config_code</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">ギフト名</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">発行数量</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">単価</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">発行金額</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {giftCardIssueReports.map((report, row) => (
        <TableRow key={row} data-cy="issueReportsTable-row">
          <TableCell
            className={classes.tableCell}
            data-cy={`issueReportsTable-apiGiftCardConfigIssueCode-${row}`}
          >
            <Typography variant="body2">
              {report.giftCardConfig.issueCode}
            </Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            data-cy={`issueReportsTable-contentNames-${row}`}
            key={`contentNames-${row}`}
          >
            <GiftCardTicketConfigsContents
              giftCardConfig={report.giftCardConfig}
            />
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-issueCount-${row}`}
          >
            <Typography variant="body2">{report.issueCount}</Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-unitPrice-${row}`}
          >
            <Typography variant="body2">
              {getLocalePrice(report.giftCardConfig.unitPrice)}
            </Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-totalPrice-${row}`}
          >
            <Typography variant="body2">
              {getLocalePrice(report.totalPrice)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
      {gifteeBoxIssueReports.map((report, row) => (
        <TableRow key={row} data-cy="issueReportsTable-gifteeBox-row">
          <TableCell
            className={classes.tableCell}
            data-cy={`issueReportsTable-gifteeBox-apiGifteeBoxConfigIssueCode-${row}`}
          >
            <Typography variant="body2">
              {report.gifteeBoxConfig.issueCode}
            </Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            data-cy={`issueReportsTable-gifteeBox-contentNames-${row}`}
            key={`contentNames-${row}`}
          >
            <GifteeBoxTemplateBrief
              gifteeBoxTemplateName={
                report.gifteeBoxConfig.gifteeBoxTemplate.name
              }
              gifteeBoxTemplateImageUrl={
                report.gifteeBoxConfig.gifteeBoxTemplate.imageUrl
              }
              gifteeBoxConfigUrlCode={report.gifteeBoxConfig.urlCode}
            />
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-gifteeBox-issueCount-${row}`}
          >
            <Typography variant="body2">{report.issueCount}</Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-gifteeBox-unitPrice-${row}`}
          >
            <Typography variant="body2">
              {getLocalePrice(report.initialPoint)}
            </Typography>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="right"
            data-cy={`issueReportsTable-gifteeBox-totalPrice-${row}`}
          >
            <Typography variant="body2">
              {getLocalePrice(report.totalPrice)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TableCell
          className={classes.footerTableCell}
          colSpan={6}
          align="right"
        >
          <Typography variant="body2">総発行金額:</Typography>
        </TableCell>
        <TableCell
          className={classes.footerTableCell}
          align="right"
          data-cy={`issueReportsTable-grandTotalPrice`}
        >
          <Typography variant="body2">
            {getLocalePrice(
              [...giftCardIssueReports, ...gifteeBoxIssueReports].reduce(
                (sumOfTotalPrice, { totalPrice }) =>
                  sumOfTotalPrice + totalPrice,
                0
              )
            )}
          </Typography>
        </TableCell>
      </TableRow>
    </TableFooter>
  </Table>
);

export default withStyles(styles)(IssueReportsTable);
