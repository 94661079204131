import { combineReducers } from 'redux';
import { AppActionTypes } from '../actions/actionType';

import { initialStateOfAdvertisingPeriod } from './projectJson/advertisingPeriod';
import { initialStateOfCampaignAdvertisingMedia } from './projectJson/campaignAdvertisingMedia';
import { initialCampaignGiftDistributionChannelDescriptions } from './projectJson/campaignGiftDistributionChannelDescriptions';
import { initialStateOfCampaignGiftDistributionChannels } from './projectJson/campaignGiftDistributionChannels';
import { initialStateOfCampaignMarketingIndustry } from './projectJson/campaignMarketingIndustry';
import { initialStateOfCampaignSolutionSystem } from './projectJson/campaignSolutionSystem';
import { initialStateOfClaimEstimate } from './projectJson/claimEstimate';
import { initialStateOfGiftConfigs } from './projectJson/giftConfigs';
import { initialStateOfGiftDistributionPeriod } from './projectJson/giftDistributionPeriod';
import { initialStateOfHostCompany } from './projectJson/hostCompany';
import projectJson, {
  initialStateOfName,
  initialStateOfDescription
} from './projectJson/index';
import { initialStateOfProjectPurposeCategory } from './projectJson/projectPurposeCategory';
import { initialStateOfselectedGiftConfigIndex } from './projectJson/selectedGiftConfigIndex';
import { initialStateOfThirdPartyService } from './projectJson/thirdPartyService';
import type { AdvertisingPeriod } from './projectJson/advertisingPeriod';
import type { CampaignAdvertisingMedia } from './projectJson/campaignAdvertisingMedia';
import type { CampaignGiftDistributionChannelDescriptions } from './projectJson/campaignGiftDistributionChannelDescriptions';
import type { CampaignGiftDistributionChannels } from './projectJson/campaignGiftDistributionChannels';
import type { CampaignMarketingIndustry } from './projectJson/campaignMarketingIndustry';
import type { CampaignSolutionSystem } from './projectJson/campaignSolutionSystem';
import type { CampaignSolutionSystemGcpSelected } from './projectJson/campaignSolutionSystemGcpSelected';
import type { GiftConfigs } from './projectJson/giftConfigs';
import type { GiftDistributionPeriod } from './projectJson/giftDistributionPeriod';
import type { HostCompany } from './projectJson/hostCompany';
import type { Name, Description } from './projectJson/index';
import type { ProjectPurposeCategory } from './projectJson/projectPurposeCategory';
import type { SelectedGiftConfigIndex } from './projectJson/selectedGiftConfigIndex';
import type { ThirdPartyService } from './projectJson/thirdPartyService';
import type { ClaimEstimate } from '../../../common/api/graphql/getClaimEstimate';
import type { AppAction } from '../actions/actionType';

export interface ProjectDraft {
  schemaVersion: SchemaVersion;
  urlCode: UrlCode;
  projectJson: ProjectJson;
}

export type SchemaVersion = number;
export type UrlCode = string;
export type ProjectJson = {
  name: Name;
  description: Description;
  hostCompany: HostCompany;
  advertisingPeriod: AdvertisingPeriod;
  giftDistributionPeriod: GiftDistributionPeriod;
  campaignSolutionSystem: CampaignSolutionSystem;
  campaignSolutionSystemGcpSelected: CampaignSolutionSystemGcpSelected;
  campaignMarketingIndustry: CampaignMarketingIndustry;
  projectPurposeCategory: ProjectPurposeCategory;
  thirdPartyService: ThirdPartyService;
  giftConfigs: GiftConfigs;
  campaignGiftDistributionChannels: CampaignGiftDistributionChannels;
  campaignGiftDistributionChannelDescriptions: CampaignGiftDistributionChannelDescriptions;
  campaignAdvertisingMedia: CampaignAdvertisingMedia;
  claimEstimate: ClaimEstimate;
  selectedGiftConfigIndex: SelectedGiftConfigIndex;
};

export const initialStateOfProjectDraft = {
  schemaVersion: 1,
  urlCode: '',
  projectJson: {
    name: initialStateOfName,
    description: initialStateOfDescription,
    hostCompany: initialStateOfHostCompany,
    advertisingPeriod: initialStateOfAdvertisingPeriod,
    giftDistributionPeriod: initialStateOfGiftDistributionPeriod,
    campaignSolutionSystem: initialStateOfCampaignSolutionSystem,
    campaignMarketingIndustry: initialStateOfCampaignMarketingIndustry,
    campaignGiftDistributionChannels:
      initialStateOfCampaignGiftDistributionChannels,
    campaignGiftDistributionChannelDescriptions:
      initialCampaignGiftDistributionChannelDescriptions,
    campaignAdvertisingMedia: initialStateOfCampaignAdvertisingMedia,
    thirdPartyService: {
      ...initialStateOfThirdPartyService
    },
    giftConfigs: initialStateOfGiftConfigs,
    claimEstimate: initialStateOfClaimEstimate,
    projectPurposeCategory: initialStateOfProjectPurposeCategory,
    selectedGiftConfigIndex: initialStateOfselectedGiftConfigIndex
  }
};

const schemaVersion = (
  state = initialStateOfProjectDraft.schemaVersion,
  action: AppAction
): SchemaVersion => {
  switch (action.type) {
    case AppActionTypes.ADD_PROJECT_DRAFT:
    case AppActionTypes.COPY_PROJECT_TO_PROJECT_DRAFT:
      return initialStateOfProjectDraft.schemaVersion;
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.schemaVersion;
    default:
      return state;
  }
};

const urlCode = (
  state = initialStateOfProjectDraft.urlCode,
  action: AppAction
): UrlCode => {
  switch (action.type) {
    case AppActionTypes.SELECT_PROJECT_DRAFT:
      return action.projectDraft.urlCode;
    case AppActionTypes.REGISTER_PROJECT_DRAFT_SUCCESS:
      return action.urlCode;
    default:
      return state;
  }
};

const selectedProjectDraft = combineReducers({
  schemaVersion,
  urlCode,
  projectJson
});

export default selectedProjectDraft;
