import { gql } from '@apollo/client';

import { internalApiAppClient } from './apolloClient';
import { operationNames } from './operationNames';
import type { MutationErrors } from './mutationErrorType';
import type {
  Project,
  SolutionSystem,
  MessageCardConfig,
  MarketingIndustry,
  ProjectGiftDistributionChannel,
  ProjectAdvertisingMediumRegistration,
  ProjectPurposeCategory
} from './projectTypes';
import type { FetchResult } from '@apollo/client';

const createProjectGql = gql`
  mutation ${operationNames.CREATE_PROJECT}(
    $name: String!
    $description: String!
    $projectDraftUrlCode: String!
    $hostCompanyOption: HostCompanyOptionInput
    $advertisingPeriod: AdvertisingPeriodInput!
    $solutionSystem: SolutionSystemInput!
    $marketingIndustry: MarketingIndustryInput
    $projectPurposeCategory: ProjectPurposeCategoryInput!
    $giftDistributionPeriod: GiftDistributionPeriodInput!
    $thirdPartyService: ThirdPartyServiceInput
    $projectGiftDistributionChannels: [ProjectGiftDistributionChannelInput!]!
    $projectAdvertisingMediumRegistrations: [ProjectAdvertisingMediumRegistrationInput!]!
    $giftCardConfigs: [GiftCardConfigInput!]
    $gifteeBoxConfigs: [GifteeBoxConfigInput!]
  ) {
    createProject(
      input: {
        name: $name
        description: $description
        projectDraftUrlCode: $projectDraftUrlCode
        hostCompanyOption: $hostCompanyOption
        advertisingPeriod: $advertisingPeriod
        solutionSystem: $solutionSystem
        marketingIndustry: $marketingIndustry
        projectPurposeCategory: $projectPurposeCategory
        giftDistributionPeriod: $giftDistributionPeriod
        thirdPartyService: $thirdPartyService
        projectGiftDistributionChannels: $projectGiftDistributionChannels
        projectAdvertisingMediumRegistrations: $projectAdvertisingMediumRegistrations
        giftCardConfigs: $giftCardConfigs
        gifteeBoxConfigs: $gifteeBoxConfigs
      }
    ) {
      project {
        urlCode
        description
      }
      errors {
        __typename
        message
        path
      }
    }
  }
`;

export type MutationProject = Project<
  MutationProjectGiftDistributionChannels,
  SolutionSystem,
  MutationGiftCardConfigs,
  MutationProjectPurposeCategory,
  MutationMarketingIndustry
> & {
  projectDraftUrlCode: string;
  hostCompanyOption?: HostCompanyOption;
  gifteeBoxConfigs: MutationGifteeBoxConfigs;
  projectAdvertisingMediumRegistrations: MutationProjectAdvertisingMediumRegistrations;
};

export type MutationMarketingIndustry = MarketingIndustry;

export type MutationProjectPurposeCategory = ProjectPurposeCategory;

export type HostCompanyOption = {
  name: string;
};

export type MutationMessageCardConfig =
  | null
  | (MessageCardConfig & {
      mainVisualUrlCode?: string;
    });

export type MutationGiftCardTicketConfig =
  | MutationFixedGiftCardTicketConfig
  | MutationChoosableGiftCardTicketConfig;

export type MutationFixedGiftCardTicketConfig = {
  fixedContentOption: {
    urlCode: string;
  };
  choosableContentOption: null;
};

export type MutationProjectGiftDistributionChannel = Omit<
  ProjectGiftDistributionChannel,
  'name'
>;

export type MutationProjectGiftDistributionChannels =
  MutationProjectGiftDistributionChannel[];

export type MutationProjectAdvertisingMediumRegistration =
  ProjectAdvertisingMediumRegistration;

export type MutationProjectAdvertisingMediumRegistrations =
  MutationProjectAdvertisingMediumRegistration[];

export type MutationChoosableGiftCardTicketConfig = {
  fixedContentOption: null;
  choosableContentOption: {
    choices: MutationChoices;
  };
};

export type MutationChoices = MutationContent[];

type MutationContent = {
  urlCode: string;
};

type MutationGiftCardConfig =
  | MutationBulkGiftCardConfig
  | MutationApiGiftCardConfig;

type MutationGiftCardConfigs = MutationGiftCardConfig[] | null;

export type MutationBulkGiftCardConfig = {
  messageCardConfig: MutationMessageCardConfig;
  giftCardTicketConfigs: MutationGiftCardTicketConfig[];
  bulkGiftCardIssueConfigs: MutationBulkGiftCardIssueConfigs;
  apiGiftCardIssueConfigs: null;
};

export type MutationApiGiftCardConfig = {
  messageCardConfig: MutationMessageCardConfig;
  giftCardTicketConfigs: MutationGiftCardTicketConfig[];
  bulkGiftCardIssueConfigs: null;
  apiGiftCardIssueConfigs: MutationApiGiftCardIssueConfigs;
};

export type MutationBulkGiftCardIssueConfig = {
  desiredDeliveryDate: string | null;
  requestedIssueAmount: number;
};

export type MutationBulkGiftCardIssueConfigs =
  | MutationBulkGiftCardIssueConfig[]
  | null;

export type MutationApiGiftCardIssueConfig = {
  issuableBeginAt: string;
  issuableEndAt: string;
  estimatedIssueAmount: number;
};

export type MutationApiGiftCardIssueConfigs =
  | MutationApiGiftCardIssueConfig[]
  | null;

export type MutationGifteeBoxConfigs = MutationGifteeBoxConfig[] | null;

type MutationGifteeBoxConfig =
  | MutationBulkGifteeBoxConfig
  | MutationApiGifteeBoxConfig;

type MutationBulkGifteeBoxConfig = {
  gifteeBoxTemplateUrlCode: string;
  bulkGifteeBoxIssueConfigs: BulkGifteeBoxIssueConfig[];
  apiGifteeBoxIssueConfigs: {}[];
};

type MutationApiGifteeBoxConfig = {
  gifteeBoxTemplateUrlCode: string;
  bulkGifteeBoxIssueConfigs: {}[];
  apiGifteeBoxIssueConfigs: ApiGifteeBoxIssueConfig[];
};

export type BulkGifteeBoxIssueConfig = {
  initialPoint: number;
  requestedIssueAmount: number;
  deliveryDate: string;
};

export type ApiGifteeBoxIssueConfig = {
  issuableBeginAt: string;
  issuableEndAt: string;
  issueEstimateOptions: ApiGifteeBoxIssueConfigIssueEstimateOptions;
};

export type ApiGifteeBoxIssueConfigIssueEstimateOptions =
  ApiGifteeBoxIssueConfigIssueEstimateOption[];

export type ApiGifteeBoxIssueConfigIssueEstimateOption = {
  initialPoint: number;
  issueAmount: number;
};

export type CreateProjectResponse = FetchResult<{
  createProject: {
    project: {
      urlCode: string;
      description: string;
    };
    errors: MutationErrors;
  };
}>;

export const hasCreateProjectProp = (arg: any) =>
  arg.data !== undefined && arg.data.createProject !== undefined;

export const createProject = (
  createProjectParams: MutationProject
): Promise<CreateProjectResponse> =>
  internalApiAppClient.mutate({
    mutation: createProjectGql,
    variables: {
      ...createProjectParams
    }
  });
