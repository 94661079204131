import CopyToClipboardButton from '@common/components/CopyToClipboardButton';
import {
  getLocaleDateString,
  getLocaleEndDateString
} from '@common/lib/getLocaleDate';
import GifteeBoxTemplateBrief from '@console/components/common/GifteeBoxTemplateBrief';
import TicketTypeChip, {
  PROJECT_TICKET_TYPE
} from '@console/components/project/common/TicketTypeChip';
import ArchivedChip from '@console/components/project/giftConfig/ArchivedChip';
import GiftUsageReviewStatusChip from '@console/components/project/giftConfig/GiftUsageReviewStatusChip';
import { Typography, TableRow, TableCell, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useState, useEffect } from 'react';
import type { QueryAPIGifteeBoxIssueConfig } from '@common/api/graphql/getProjectsGql';
import type {
  LocalGifteeBoxConfig,
  LocalAPIConfidentialInformation
} from '@console/reducers/projects';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  tableCell: {
    padding: theme.spacing(2),
    verticalAlign: 'middle'
  },
  disabled: {
    backgroundColor: theme.palette.text.disabled
  }
});

type OwnProps = {
  isOldReviewProcess: boolean;
  gifteeBoxConfig: LocalGifteeBoxConfig;
  confidentialInformationGifteeBoxConfig:
    | LocalAPIConfidentialInformation['gifteeBoxConfigs'][number]
    | null
    | undefined;
  handleGifteeBoxTemplateContentSelectionsModalOpen: (
    gifteeBoxTemplate: LocalGifteeBoxConfig
  ) => void;
};

type GifteeBoxConfigRowProps = OwnProps & WithStyles<typeof styles>;

const GifteeBoxConfigRow: React.FC<GifteeBoxConfigRowProps> = ({
  classes,
  isOldReviewProcess,
  gifteeBoxConfig,
  confidentialInformationGifteeBoxConfig,
  handleGifteeBoxTemplateContentSelectionsModalOpen
}) => {
  const [lastApiGifteeBoxIssueConfig, setLastApiGifteeBoxIssueConfig] =
    useState<null | LocalGifteeBoxConfig['apiGifteeBoxIssueConfigs'][number]>(
      null
    );

  useEffect(() => {
    const { apiGifteeBoxIssueConfigs } = gifteeBoxConfig;
    setLastApiGifteeBoxIssueConfig(
      apiGifteeBoxIssueConfigs[apiGifteeBoxIssueConfigs.length - 1]
    );
  }, [gifteeBoxConfig.apiGifteeBoxIssueConfigs]);

  const isDisabledGifteeBoxIssueConfig = (
    gifteeBoxIssueConfig: QueryAPIGifteeBoxIssueConfig | null
  ) =>
    !!gifteeBoxIssueConfig?.issuableEndAt &&
    new Date(gifteeBoxIssueConfig.issuableEndAt) < new Date();

  return (
    <TableRow
      className={
        isDisabledGifteeBoxIssueConfig(
          gifteeBoxConfig.currentApiGifteeBoxIssueConfig
        )
          ? classes.disabled
          : ''
      }
      data-cy={`gifteeBoxConfigRow-${gifteeBoxConfig.urlCode}`}
    >
      {isOldReviewProcess ? null : (
        <TableCell
          className={classes.tableCell}
          align="center"
          data-cy={`gifteeBoxConfigRowGiftUsageReviewStatus-${gifteeBoxConfig.urlCode}`}
        >
          {gifteeBoxConfig.archived ? (
            <ArchivedChip />
          ) : (
            <GiftUsageReviewStatusChip
              status={gifteeBoxConfig.giftUsageReviewStatus}
            />
          )}
        </TableCell>
      )}
      <TableCell
        className={classes.tableCell}
        align="center"
        data-cy={`gifteeBoxConfigRowTicketType-${gifteeBoxConfig.urlCode}`}
      >
        <TicketTypeChip ticketType={PROJECT_TICKET_TYPE.GIFTEE_BOX} />
      </TableCell>
      <TableCell
        className={classes.tableCell}
        align="left"
        data-cy={`gifteeBoxConfigRowContent-${gifteeBoxConfig.urlCode}`}
      >
        <GifteeBoxTemplateBrief
          gifteeBoxTemplateName={gifteeBoxConfig.gifteeBoxTemplate.name}
          gifteeBoxTemplateImageUrl={gifteeBoxConfig.gifteeBoxTemplate.imageUrl}
          gifteeBoxConfigUrlCode={gifteeBoxConfig.urlCode}
          handleButtonClick={() =>
            handleGifteeBoxTemplateContentSelectionsModalOpen(gifteeBoxConfig)
          }
          exchangeableEndLabel={
            lastApiGifteeBoxIssueConfig?.gifteeBoxIssueConfig
              .exchangeableEndLabel
          }
          imageDownloadable={true}
        />
      </TableCell>
      <TableCell
        className={classes.tableCell}
        align="right"
        data-cy={`gifteeBoxConfigRowIssueCode-${gifteeBoxConfig.urlCode}`}
      >
        {confidentialInformationGifteeBoxConfig?.issueCode ? (
          <>
            <div>{confidentialInformationGifteeBoxConfig.issueCode}</div>
            <CopyToClipboardButton
              copyText={confidentialInformationGifteeBoxConfig.issueCode}
              tooltipPlacement="left"
            />
          </>
        ) : (
          'まだ発行されていません'
        )}
      </TableCell>
      <TableCell
        className={classes.tableCell}
        align="center"
        data-cy={`gifteeBoxConfigRowIssuableTimeSpan-${gifteeBoxConfig.urlCode}`}
      >
        <Typography
          variant="body2"
          data-cy={`gifteeBoxConfigRowIssuableBeginAt-${gifteeBoxConfig.urlCode}`}
        >
          {gifteeBoxConfig.currentApiGifteeBoxIssueConfig
            ? getLocaleDateString(
                gifteeBoxConfig.currentApiGifteeBoxIssueConfig.issuableBeginAt
              )
            : null}
        </Typography>
      </TableCell>
      <TableCell
        className={classes.tableCell}
        align="center"
        data-cy={`gifteeBoxConfigRowIssuableTimeSpan-${gifteeBoxConfig.urlCode}`}
      >
        <Typography
          variant="body2"
          data-cy={`gifteeBoxConfigRowIssuableEndAt-${gifteeBoxConfig.urlCode}`}
        >
          {gifteeBoxConfig.currentApiGifteeBoxIssueConfig
            ? getLocaleEndDateString(
                gifteeBoxConfig.currentApiGifteeBoxIssueConfig.issuableEndAt
              )
            : null}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(GifteeBoxConfigRow);
