import type * as actions from '@console/actions/actions';

export type AppAction = ActionType<typeof actions>;

export enum AppActionTypes {
  UPDATE_IS_LOGGED_IN = 'UPDATE_IS_LOGGED_IN',
  GET_LOGIN_USER_START = 'GET_LOGIN_USER_START',
  GET_LOGIN_USER_SUCCESS = 'GET_LOGIN_USER_SUCCESS',
  GET_LOGIN_USER_FAILURE = 'GET_LOGIN_USER_FAILURE',
  OPEN_DRAWER = 'OPEN_DRAWER',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
  TOGGLE_SETTING_MENU = 'TOGGLE_SETTING_MENU',
  UPDATE_NOTIFICATION_NUMBER = 'UPDATE_NOTIFICATION_NUMBER',
  ADD_PROJECT_DRAFT = 'ADD_PROJECT_DRAFT',
  SELECT_PROJECT_DRAFT = 'SELECT_PROJECT_DRAFT',
  GET_PROJECT_DRAFTS_START = 'GET_PROJECT_DRAFTS_START',
  GET_PROJECT_DRAFTS_SUCCESS = 'GET_PROJECT_DRAFTS_SUCCESS',
  GET_PROJECT_DRAFTS_FAILURE = 'GET_PROJECT_DRAFTS_FAILURE',
  GET_PROJECT_DETAIL_START = 'GET_PROJECT_DETAIL_START',
  GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS',
  GET_PROJECT_DETAIL_FAILURE = 'GET_PROJECT_DETAIL_FAILURE',
  GET_PROJECTS_START = 'GET_PROJECTS_START',
  GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE',
  SET_SELECTED_PROJECT_URL_CODE = 'SET_SELECTED_PROJECT_URL_CODE',
  UPDATE_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_ISSUE_AMOUNT = 'UPDATE_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_ISSUE_AMOUNT',
  UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG = 'UPDATE_SELECTED_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG',
  OPEN_PROJECT_GIFT_CARD_PREVIEW = 'OPEN_PROJECT_GIFT_CARD_PREVIEW',
  OPEN_PROJECT_DRAFT_OPTION = 'OPEN_PROJECT_DRAFT_OPTION',
  CLOSE_PROJECT_DRAFT_OPTION = 'CLOSE_PROJECT_DRAFT_OPTION',
  UPDATE_PROJECT_DRAFTS = 'UPDATE_PROJECT_DRAFTS',
  UPDATE_HOST_COMPANY_RELATION_TO_DP = 'UPDATE_HOST_COMPANY_RELATION_TO_DP',
  UPDATE_HOST_COMPANY_NAME = 'UPDATE_HOST_COMPANY_NAME',
  UPDATE_CAMPAIGN_NAME = 'UPDATE_CAMPAIGN_NAME',
  FOCUS_OUT_FROM_CAMPAIGN_NAME = 'FOCUS_OUT_FROM_CAMPAIGN_NAME',
  UPDATE_CAMPAIGN_DESCRIPTION = 'UPDATE_CAMPAIGN_DESCRIPTION',
  UPDATE_START_DATE_OF_AD = 'UPDATE_START_DATE_OF_AD',
  UPDATE_END_DATE_OF_AD = 'UPDATE_END_DATE_OF_AD',
  UPDATE_START_DATE_OF_DISTRIBUTION = 'UPDATE_START_DATE_OF_DISTRIBUTION',
  UPDATE_END_DATE_OF_DISTRIBUTION = 'UPDATE_END_DATE_OF_DISTRIBUTION',
  UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK = 'UPDATE_CAMPAIGN_SOLUTION_SYSTEM_API_BULK',
  UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP = 'UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP',
  UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP_SELECTED = 'UPDATE_CAMPAIGN_SOLUTION_SYSTEM_GCP_SELECTED',
  UPDATE_CAMPAIGN_MARKETING_INDUSTRY = 'UPDATE_CAMPAIGN_MARKETING_INDUSTRY',
  UPDATE_PROJECT_PURPOSE_CATEGORY = 'UPDATE_PROJECT_PURPOSE_CATEGORY',
  UPDATE_MARKETING_INDUSTRY = 'UPDATE_MARKETING_INDUSTRY',
  TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL = 'TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL',
  TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL_CATEGORY = 'TOGGLE_CAMPAIGN_DISTRIBUTION_CHANNEL_CATEGORY',
  UPDATE_GIFT_DISTRIBUTION_CHANNEL_DESCRIPTION = 'UPDATE_GIFT_DISTRIBUTION_CHANNEL_DESCRIPTION',
  TOGGLE_CAMPAIGN_ADVERTISING_MEDIUM = 'TOGGLE_CAMPAIGN_ADVERTISING_MEDIUM',
  UPDATE_CAMPAIGN_ADVERTISING_MEDIUM_DESCRIPTION = 'UPDATE_CAMPAIGN_ADVERTISING_MEDIUM_DESCRIPTION',
  UPDATE_NAME_OF_THIRD_PARTY_SERVICE = 'UPDATE_NAME_OF_THIRD_PARTY_SERVICE',
  UPDATE_URL_OF_THIRD_PARTY_SERVICE = 'UPDATE_URL_OF_THIRD_PARTY_SERVICE',
  UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE = 'UPDATE_DESCRIPTION_OF_THIRD_PARTY_SERVICE',
  REGISTER_PROJECT_DRAFT_START = 'REGISTER_PROJECT_DRAFT_START',
  REGISTER_PROJECT_DRAFT_SUCCESS = 'REGISTER_PROJECT_DRAFT_SUCCESS',
  REGISTER_PROJECT_DRAFT_FAILURE = 'REGISTER_PROJECT_DRAFT_FAILURE',
  SET_SELECTED_GIFT_CONFIG = 'SET_SELECTED_GIFT_CONFIG',
  ADD_NEW_GIFT_CONFIG = 'ADD_NEW_GIFT_CONFIG',
  SELECT_NEW_GIFT_CONFIG = 'SELECT_NEW_GIFT_CONFIG',
  DELETE_GIFT_CONFIG = 'DELETE_GIFT_CONFIG',
  INITIALIZE_SELECTED_GIFT_CONFIG = 'INITIALIZE_SELECTED_GIFT_CONFIG',
  UPDATE_GIFT_CONFIG_TICKET_TYPE = 'UPDATE_GIFT_CONFIG_TICKET_TYPE',
  ADD_GIFT_CONFIG_SELECTED_CONTENTS = 'ADD_GIFT_CONFIG_SELECTED_CONTENTS',
  REMOVE_GIFT_CONFIG_SELECTED_CONTENTS = 'REMOVE_GIFT_CONFIG_SELECTED_CONTENTS',
  UPDATE_GIFT_CONFIG_ISSUE_AMOUNT = 'UPDATE_GIFT_CONFIG_ISSUE_AMOUNT',
  UPDATE_GIFT_CONFIG_DELIVERY_DATE = 'UPDATE_GIFT_CONFIG_DELIVERY_DATE',
  UPDATE_GIFT_CONFIG_GIFTEE_BOX_INITIAL_POINT = 'UPDATE_GIFT_CONFIG_GIFTEE_BOX_INITIAL_POINT',
  UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_INITIAL_POINT = 'UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_INITIAL_POINT',
  UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_ISSUE_AMOUNT = 'UPDATE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTIONS_ISSUE_AMOUNT',
  ADD_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION = 'ADD_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION',
  REMOVE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION = 'REMOVE_GIFT_CONFIG_GIFTEE_BOX_ISSUE_ESTIMATE_OPTION',
  UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING = 'UPDATE_GIFT_CONFIG_MESSAGE_CARD_USING',
  UPDATE_GIFT_CONFIG_SENDER_NAME = 'UPDATE_GIFT_CONFIG_SENDER_NAME',
  UPDATE_GIFT_CONFIG_MESSAGE = 'UPDATE_GIFT_CONFIG_MESSAGE',
  UPDATE_GIFT_CONFIG_MAIN_VISUAL = 'UPDATE_GIFT_CONFIG_MAIN_VISUAL',
  GET_BRANDS_START = 'GET_BRANDS_START',
  GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS',
  GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE',
  OPEN_CONTENTS_PAGE = 'OPEN_CONTENTS_PAGE',
  OPEN_PROJECT_DRAFT_CONTENTS_SELECTION_PAGE = 'OPEN_PROJECT_DRAFT_CONTENTS_SELECTION_PAGE',
  GET_INITIAL_CONTENTS_START = 'GET_INITIAL_CONTENTS_START',
  GET_INITIAL_CONTENTS_SUCCESS = 'GET_INITIAL_CONTENTS_SUCCESS',
  GET_INITIAL_CONTENTS_FAILURE = 'GET_INITIAL_CONTENTS_FAILURE',
  GET_CONTINUOUS_CONTENTS_START = 'GET_CONTINUOUS_CONTENTS_START',
  GET_CONTINUOUS_CONTENTS_SUCCESS = 'GET_CONTINUOUS_CONTENTS_SUCCESS',
  GET_CONTINUOUS_CONTENTS_FAILURE = 'GET_CONTINUOUS_CONTENTS_FAILURE',
  GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_START = 'GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_START',
  GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_SUCCESS = 'GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_SUCCESS',
  GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_FAILURE = 'GET_CONTENTS_OF_SELECTED_PROJECT_DRAFT_FAILURE',
  CHANGE_CONTENTS_BRAND_FILTER = 'CHANGE_CONTENTS_BRAND_FILTER',
  CHANGE_CONTENTS_PRICE_RANGE_FILTER = 'CHANGE_CONTENTS_PRICE_RANGE_FILTER',
  GET_GIFT_CARD_MAIN_VISUALS_START = 'GET_GIFT_CARD_MAIN_VISUALS_START',
  GET_GIFT_CARD_MAIN_VISUALS_SUCCESS = 'GET_GIFT_CARD_MAIN_VISUALS_SUCCESS',
  GET_GIFT_CARD_MAIN_VISUALS_FAILURE = 'GET_GIFT_CARD_MAIN_VISUALS_FAILURE',
  GET_SOLUTION_SYSTEMS_START = 'GET_SOLUTION_SYSTEMS_START',
  GET_SOLUTION_SYSTEMS_SUCCESS = 'GET_SOLUTION_SYSTEMS_SUCCESS',
  GET_SOLUTION_SYSTEMS_FAILURE = 'GET_SOLUTION_SYSTEMS_FAILURE',
  GET_MARKETING_INDUSTRIES_START = 'GET_MARKETING_INDUSTRIES_START',
  GET_MARKETING_INDUSTRIES_SUCCESS = 'GET_MARKETING_INDUSTRIES_SUCCESS',
  GET_MARKETING_INDUSTRIES_FAILURE = 'GET_MARKETING_INDUSTRIES_FAILURE',
  GET_GENERAL_ANNOUNCEMENTS_START = 'GET_GENERAL_ANNOUNCEMENTS_START',
  GET_GENERAL_ANNOUNCEMENTS_SUCCESS = 'GET_GENERAL_ANNOUNCEMENTS_SUCCESS',
  GET_GENERAL_ANNOUNCEMENTS_FAILURE = 'GET_GENERAL_ANNOUNCEMENTS_FAILURE',
  GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_START = 'GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_START',
  GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_SUCCESS = 'GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_SUCCESS',
  GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_FAILURE = 'GET_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES_FAILURE',
  GET_ADVERTISING_MEDIA_START = 'GET_ADVERTISING_MEDIA_START',
  GET_ADVERTISING_MEDIA_SUCCESS = 'GET_ADVERTISING_MEDIA_SUCCESS',
  GET_ADVERTISING_MEDIA_FAILURE = 'GET_ADVERTISING_MEDIA_FAILURE',
  GET_PREFECTURES_START = 'GET_PREFECTURES_START',
  GET_PREFECTURES_SUCCESS = 'GET_PREFECTURES_SUCCESS',
  GET_PREFECTURES_FAILURE = 'GET_PREFECTURES_FAILURE',
  UPDATE_PROJECT_DRAFT_START = 'UPDATE_PROJECT_DRAFT_START',
  UPDATE_PROJECT_DRAFT_SUCCESS = 'UPDATE_PROJECT_DRAFT_SUCCESS',
  UPDATE_PROJECT_DRAFT_FAILURE = 'UPDATE_PROJECT_DRAFT_FAILURE',
  GET_MESSAGE_THREAD_START = 'GET_MESSAGE_THREAD_START',
  GET_MESSAGE_THREAD_SUCCESS = 'GET_MESSAGE_THREAD_SUCCESS',
  GET_MESSAGE_THREAD_FAILURE = 'GET_MESSAGE_THREAD_FAILURE',
  UPDATE_MESSAGE_THREAD_INPUT_MESSAGES = 'UPDATE_MESSAGE_THREAD_INPUT_MESSAGES',
  ADD_MESSAGE_THREAD_ATTACHED_FILES = 'ADD_MESSAGE_THREAD_ATTACHED_FILES',
  REMOVE_MESSAGE_THREAD_ATTACHED_FILES = 'REMOVE_MESSAGE_THREAD_ATTACHED_FILES',
  REMOVE_MESSAGE_THREAD_ATTACHED_FILES_ALL = 'REMOVE_MESSAGE_THREAD_ATTACHED_FILES_ALL',
  POST_MESSAGE_THREAD_MESSAGE_START = 'POST_MESSAGE_THREAD_MESSAGE_START',
  POST_MESSAGE_THREAD_MESSAGE_SUCCESS = 'POST_MESSAGE_THREAD_MESSAGE_SUCCESS',
  POST_MESSAGE_THREAD_MESSAGE_FAILURE = 'POST_MESSAGE_THREAD_MESSAGE_FAILURE',
  RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_SUCCESS = 'RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_SUCCESS',
  RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_FAILURE = 'RECEIVE_MESSAGE_THREAD_POSTED_MESSAGE_FAILURE',
  INITIALIZE_PROJECT_DRAFT_SYNC_STATUS = 'INITIALIZE_PROJECT_DRAFT_SYNC_STATUS',
  UPLOAD_FILE_START = 'UPLOAD_FILE_START',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE',
  CREATE_PROJECT_START = 'CREATE_PROJECT_START',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE',
  REQUEST_GIFT_CARD_ISSUE_AMOUNT_START = 'REQUEST_GIFT_CARD_ISSUE_AMOUNT_START',
  REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS = 'REQUEST_GIFT_CARD_ISSUE_AMOUNT_SUCCESS',
  REQUEST_GIFT_CARD_ISSUE_AMOUNT_FAILURE = 'REQUEST_GIFT_CARD_ISSUE_AMOUNT_FAILURE',
  REQUEST_BULK_DELIVERABLE_URL_START = 'REQUEST_BULK_DELIVERABLE_URL_START',
  REQUEST_BULK_DELIVERABLE_URL_SUCCESS = 'REQUEST_BULK_DELIVERABLE_URL_SUCCESS',
  REQUEST_BULK_DELIVERABLE_URL_FAILURE = 'REQUEST_BULK_DELIVERABLE_URL_START',
  REQUEST_MESSAGE_THREAD_FILE_URL_START = 'REQUEST_MESSAGE_THREAD_FILE_URL_START',
  REQUEST_MESSAGE_THREAD_FILE_URL_SUCCESS = 'REQUEST_MESSAGE_THREAD_FILE_URL_SUCCESS',
  REQUEST_MESSAGE_THREAD_FILE_URL_FAILURE = 'REQUEST_MESSAGE_THREAD_FILE_URL_FAILURE',
  SET_SELECTED_CONTENT_URL_CODE = 'SET_SELECTED_CONTENT_URL_CODE',
  REMOVE_SELECTED_CONTENT_URL_CODE = 'REMOVE_SELECTED_CONTENT_URL_CODE',
  GET_CLAIM_ESTIMATE_START = 'GET_CLAIM_ESTIMATE_START',
  GET_CLAIM_ESTIMATE_SUCCESS = 'GET_CLAIM_ESTIMATE_SUCCESS',
  GET_CLAIM_ESTIMATE_FAILURE = 'GET_CLAIM_ESTIMATE_FAILURE',
  GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_START = 'GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_START',
  GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS = 'GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS',
  GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE = 'GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE',
  GET_DEFAULT_GIFT_CARD_CONFIG_START = 'GET_DEFAULT_GIFT_CARD_CONFIG_START',
  GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS = 'GET_DEFAULT_GIFT_CARD_CONFIG_SUCCESS',
  GET_DEFAULT_GIFT_CARD_CONFIG_FAILURE = 'GET_DEFAULT_GIFT_CARD_CONFIG_FAILURE',
  VALIDATE_BULK_PROJECT_DRAFT = 'VALIDATE_BULK_PROJECT_DRAFT',
  VALIDATE_API_PROJECT_DRAFT = 'VALIDATE_API_PROJECT_DRAFT',
  VALIDATE_GCP_PROJECT_DRAFT = 'VALIDATE_GCP_PROJECT_DRAFT',
  VALIDATE_CAMPAIGN_OVERVIEW = 'VALIDATE_CAMPAIGN_OVERVIEW',
  VALIDATE_GIFT_DISTRIBUTION = 'VALIDATE_GIFT_DISTRIBUTION',
  VALIDATE_GIFT_CONFIGS_FOR_BULK_PROJECT = 'VALIDATE_GIFT_CONFIGS_FOR_BULK_PROJECT',
  VALIDATE_GIFT_CONFIGS_FOR_API_PROJECT = 'VALIDATE_GIFT_CONFIGS_FOR_API_PROJECT',
  VALIDATE_GIFT_CONFIGS_FOR_GCP_PROJECT = 'VALIDATE_GIFT_CONFIGS_FOR_GCP_PROJECT',
  VALIDATE_FIXED_CONTENT_SELECTION = 'VALIDATE_FIXED_CONTENT_SELECTION',
  VALIDATE_CHOOSABLE_CONTENTS_SELECTION = 'VALIDATE_CHOOSABLE_CONTENTS_SELECTION',
  VALIDATE_GIFTEE_BOX_TEMPLATE_SELECTION = 'VALIDATE_GIFTEE_BOX_TEMPLATE_SELECTION',
  VALIDATE_DELIVERY_FOR_BULK_PROJECT = 'VALIDATE_DELIVERY_FOR_BULK_PROJECT',
  VALIDATE_DELIVERY_FOR_API_PROJECT = 'VALIDATE_DELIVERY_FOR_API_PROJECT',
  VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_BULK_PROJECT = 'VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_BULK_PROJECT',
  VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_GCP_PROJECT = 'VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_GCP_PROJECT',
  VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_API_PROJECT = 'VALIDATE_GIFTEE_BOX_DELIVERY_CONFIG_FOR_API_PROJECT',
  VALIDATE_MESSAGE_CARD_CONFIG = 'VALIDATE_MESSAGE_CARD_CONFIG',
  VALIDATE_PROJECT_DRAFT_FAILURE = 'VALIDATE_PROJECT_DRAFT_FAILURE',
  VALIDATION_GIFT_CONFIG_COMMON_SUCCESS = 'VALIDATION_GIFT_CONFIG_COMMON_SUCCESS',
  VALIDATION_GIFT_CONFIG_COMMON_FAILURE = 'VALIDATION_GIFT_CONFIG_COMMON_FAILURE',
  VALIDATION_GIFT_CONFIG_SUCCESS = 'VALIDATION_GIFT_CONFIG_SUCCESS',
  VALIDATION_GIFT_CONFIG_FAILURE = 'VALIDATION_GIFT_CONFIG_FAILURE',
  VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS = 'VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS',
  VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS = 'VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS',
  VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE = 'VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE',
  VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_SUCCESS = 'VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_SUCCESS',
  VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_FAILURE = 'VALIDATE_PROJECT_ADDING_BULK_GIFT_CARD_ISSUE_CONFIGS_TOTAL_FAILURE',
  VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS = 'VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS',
  VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS = 'VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS',
  VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE = 'VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE',
  VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_SUCCESS = 'VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_SUCCESS',
  VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_FAILURE = 'VALIDATE_PROJECT_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIGS_TOTAL_FAILURE',
  VALIDATE_SUCCESS = 'VALIDATE_SUCCESS',
  VALIDATE_FAILURE = 'VALIDATE_FAILURE',
  MUTATION_FAILURE = 'MUTATION_FAILURE',
  GRAPHQL_FAILURE = 'GRAPHQL_FAILURE',
  INITIALIZE_SNACKBAR_MESSAGE = 'INITIALIZE_SNACKBAR_MESSAGE',
  EXCLUDE_INVALID_SOlUTION_SYSTEM = 'EXCLUDE_INVALID_SOlUTION_SYSTEM',
  EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNELS = 'EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNELS',
  EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES = 'EXCLUDE_INVALID_GIFT_DISTRIBUTION_CHANNEL_CATEGORIES',
  EXCLUDE_INVALID_CAMPAIGN_ADVERTISING_MEDIA = 'EXCLUDE_INVALID_CAMPAIGN_ADVERTISING_MEDIA',
  EXCLUDE_INVALID_GIFT_CONFIGS = 'EXCLUDE_INVALID_GIFT_CONFIGS',
  UPDATE_BILLING_ADDRESS_NAME = 'UPDATE_BILLING_ADDRESS_NAME',
  UPDATE_BILLING_ADDRESS_DIVISION = 'UPDATE_BILLING_ADDRESS_DIVISION',
  UPDATE_BILLING_ADDRESS_POSTAL_CODE = 'UPDATE_BILLING_ADDRESS_POSTAL_CODE',
  UPDATE_BILLING_ADDRESS_PREFECTURE_ID = 'UPDATE_BILLING_ADDRESS_PREFECTURE_ID',
  UPDATE_BILLING_ADDRESS_CITY = 'UPDATE_BILLING_ADDRESS_CITY',
  UPDATE_BILLING_ADDRESS_ADDRESS = 'UPDATE_BILLING_ADDRESS_ADDRESS',
  UPDATE_BILLING_ADDRESS_OPTIONAL_ADDRESS = 'UPDATE_BILLING_ADDRESS_OPTIONAL_ADDRESS',
  SEARCH_POSTCODES_START = 'SEARCH_POSTCODES_START',
  SEARCH_POSTCODES_SUCCESS = 'SEARCH_POSTCODES_SUCCESS',
  SEARCH_POSTCODES_FAILURE = 'SEARCH_POSTCODES_FAILURE',
  REQUEST_INVOICE_PDF_URL_START = 'REQUEST_INVOICE_PDF_URL_START',
  REQUEST_INVOICE_PDF_URL_SUCCESS = 'REQUEST_INVOICE_PDF_URL_SUCCESS',
  REQUEST_INVOICE_PDF_URL_FAILURE = 'REQUEST_INVOICE_PDF_URL_FAILURE',
  UPDATE_DEFAULT_BILLING_ADDRESS_START = 'UPDATE_DEFAULT_BILLING_ADDRESS_START',
  UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS = 'UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS',
  UPDATE_DEFAULT_BILLING_ADDRESS_FAILURE = 'UPDATE_DEFAULT_BILLING_ADDRESS_FAILURE',
  OPEN_DISTRIBUTION_PARTNER_EDIT = 'OPEN_DISTRIBUTION_PARTNER_EDIT',
  UPDATE_DISTRIBUTION_PARTNER_USER_NAME = 'UPDATE_DISTRIBUTION_PARTNER_USER_NAME',
  UPDATE_DISTRIBUTION_PARTNER_USER_DIVISION = 'UPDATE_DISTRIBUTION_PARTNER_USER_DIVISION',
  UPDATE_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER = 'UPDATE_DISTRIBUTION_PARTNER_USER_PHONE_NUMBER',
  UPDATE_DISTRIBUTION_PARTNER_USER_START = 'UPDATE_DISTRIBUTION_PARTNER_USER_START',
  UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS = 'UPDATE_DISTRIBUTION_PARTNER_USER_SUCCESS',
  UPDATE_DISTRIBUTION_PARTNER_USER_FAILURE = 'UPDATE_DISTRIBUTION_PARTNER_USER_FAILURE',
  OPEN_API_IP_RESTRICTION = 'OPEN_API_IP_RESTRICTION',
  UPDATE_EDIT_API_ALLOWED_IP_ADDRESS = 'UPDATE_EDIT_API_ALLOWED_IP_ADDRESS',
  UPDATE_API_ALLOWED_IP_START = 'UPDATE_API_ALLOWED_IP_START',
  UPDATE_API_ALLOWED_IP_SUCCESS = 'UPDATE_API_ALLOWED_IP_SUCCESS',
  UPDATE_API_ALLOWED_IP_FAILURE = 'UPDATE_API_ALLOWED_IP_FAILURE',
  DELETE_API_ALLOWED_IP_START = 'DELETE_API_ALLOWED_IP_START',
  DELETE_API_ALLOWED_IP_SUCCESS = 'DELETE_API_ALLOWED_IP_SUCCESS',
  DELETE_API_ALLOWED_IP_FAILURE = 'DELETE_API_ALLOWED_IP_FAILURE',
  ADD_API_ALLOWED_IP_START = 'ADD_API_ALLOWED_IP_START',
  ADD_API_ALLOWED_IP_SUCCESS = 'ADD_API_ALLOWED_IP_SUCCESS',
  ADD_API_ALLOWED_IP_FAILURE = 'ADD_API_ALLOWED_IP_FAILURE',
  EXIT_API_ALLOWED_IP_ADD_MODAL = 'EXIT_API_ALLOWED_IP_ADD_MODAL',
  CREATE_SAMPLE_PROJECT_START = 'CREATE_SAMPLE_PROJECT_START',
  CREATE_SAMPLE_PROJECT_SUCCESS = 'CREATE_SAMPLE_PROJECT_SUCCESS',
  CREATE_SAMPLE_PROJECT_FAILURE = 'CREATE_SAMPLE_PROJECT_FAILURE',
  DELETE_PROJECT_DRAFT_START = 'DELETE_PROJECT_DRAFT_START',
  DELETE_PROJECT_DRAFT_SUCCESS = 'DELETE_PROJECT_DRAFT_SUCCESS',
  DELETE_PROJECT_DRAFT_FAILURE = 'DELETE_PROJECT_DRAFT_FAILURE',
  GET_MASTER_DATA_START = 'GET_MASTER_DATA_START',
  GET_MASTER_DATA_SUCCESS = 'GET_MASTER_DATA_SUCCESS',
  GET_MASTER_DATA_FAILURE = 'GET_MASTER_DATA_FAILURE',
  GET_ISSUE_COUNTS_START = 'GET_ISSUE_COUNTS_START',
  GET_ISSUE_COUNTS_SUCCESS = 'GET_ISSUE_COUNTS_SUCCESS',
  GET_ISSUE_COUNTS_FAILURE = 'GET_ISSUE_COUNTS_FAILURE',
  SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_FROM = 'SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_FROM',
  SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_TO = 'SET_ISSUE_REPORTS_FILTER_ISSUE_DATE_TO',
  CLOSE_PROJECT_GIFT_CONFIG_BULK_TABLE = 'CLOSE_PROJECT_GIFT_CONFIG_BULK_TABLE',
  COPY_PROJECT_TO_PROJECT_DRAFT = 'COPY_PROJECT_TO_PROJECT_DRAFT',
  COPY_PROJECT_TO_PROJECT_DRAFT_SUCCESS = 'COPY_PROJECT_TO_PROJECT_DRAFT_SUCCESS',
  COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE = 'COPY_PROJECT_TO_PROJECT_DRAFT_FAILURE',
  CHECK_IF_PROJECT_EXISTS_START = 'CHECK_IF_PROJECT_EXISTS_START',
  CHECK_IF_PROJECT_EXISTS_SUCCESS = 'CHECK_IF_PROJECT_EXISTS_SUCCESS',
  CHECK_IF_PROJECT_EXISTS_FAILURE = 'CHECK_IF_PROJECT_EXISTS_FAILURE',
  GET_GIFTEE_BOX_TEMPLATES_START = 'GET_GIFTEE_BOX_TEMPLATES_START',
  GET_GIFTEE_BOX_TEMPLATES_SUCCESS = 'GET_GIFTEE_BOX_TEMPLATES_SUCCESS',
  GET_GIFTEE_BOX_TEMPLATES_FAILURE = 'GET_GIFTEE_BOX_TEMPLATES_FAILURE',
  SELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE = 'SELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE',
  DESELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE = 'DESELECT_GIFT_CONFIG_GIFTEE_BOX_TEMPLATE',
  GET_SELECTED_PROJECT_DRAFT_CONTENTS_START = 'GET_SELECTED_PROJECT_DRAFT_CONTENTS_START',
  GET_SELECTED_PROJECT_DRAFT_CONTENTS_SUCCESS = 'GET_SELECTED_PROJECT_DRAFT_CONTENTS_SUCCESS',
  GET_SELECTED_PROJECT_DRAFT_CONTENTS_FAILURE = 'GET_SELECTED_PROJECT_DRAFT_CONTENTS_FAILURE',
  GET_PROJECT_DRAFTS_CONTENTS_START = 'GET_PROJECT_DRAFTS_CONTENTS_START',
  GET_PROJECT_DRAFTS_CONTENTS_SUCCESS = 'GET_PROJECT_DRAFTS_CONTENTS_SUCCESS',
  GET_PROJECT_DRAFTS_CONTENTS_FAILURE = 'GET_PROJECT_DRAFTS_CONTENTS_FAILURE',
  UPDATE_PROJECT_BULK_GIFTEE_BOX_ISSUE_CONFIG_ISSUE_AMOUNT = 'UPDATE_PROJECT_BULK_GIFTEE_BOX_ISSUE_CONFIG_ISSUE_AMOUNT',
  GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_START = 'GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_START',
  GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS = 'GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS',
  GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE = 'GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_FAILURE',
  REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_START = 'REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_START',
  REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_SUCCESS = 'REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_SUCCESS',
  REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_FAILURE = 'REQUEST_GIFTEE_BOX_ISSUE_AMOUNT_FAILURE',
  GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_START = 'GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_START',
  GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS = 'GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_SUCCESS',
  GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_FAILURE = 'GET_GIFTEE_BOX_TEMPLATE_INITIAL_CONTENT_SELECTIONS_FAILURE',
  SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT = 'SELECT_GIFTEE_BOX_TEMPLATE_FOR_DRAFT_PROJECT',
  SELECT_GIFTEE_BOX_TEMPLATE = 'SELECT_GIFTEE_BOX_TEMPLATE',
  DESELECT_GIFTEE_BOX_TEMPLATE = 'DESELECT_GIFTEE_BOX_TEMPLATE',
  GET_GIFTEE_BOX_TEMPLATE_BRANDS_START = 'GET_GIFTEE_BOX_TEMPLATE_BRANDS_START',
  GET_GIFTEE_BOX_TEMPLATE_BRANDS_SUCCESS = 'GET_GIFTEE_BOX_TEMPLATE_BRANDS_SUCCESS',
  GET_GIFTEE_BOX_TEMPLATE_BRANDS_FAILURE = 'GET_GIFTEE_BOX_TEMPLATE_BRANDS_FAILURE',
  GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_START = 'GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_START',
  GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS = 'GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_SUCCESS',
  GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_FAILURE = 'GET_GIFTEE_BOX_TEMPLATE_CONTINUOUS_CONTENT_SELECTIONS_FAILURE',
  UPDATE_GIFTEE_BOX_TEMPLATE_BRAND_FILTER = 'UPDATE_GIFTEE_BOX_TEMPLATE_BRAND_FILTER',
  SELECT_BULK_GIFTEE_BOX_ISSUE_CONFIG = 'SELECT_BULK_GIFTEE_BOX_ISSUE_CONFIG',
  INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW = 'INITIALIZE_BULK_GIFT_CARD_ISSUE_CONFIGS_NEW',
  UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT = 'UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT',
  UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_DESIRED_DELIVERY_DATE = 'UPDATE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG_DESIRED_DELIVERY_DATE',
  GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_START = 'GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_START',
  GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS = 'GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_SUCCESS',
  GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_FAILURE = 'GET_BULK_ISSUE_CONFIGS_CLAIM_ESTIMATE_FAILURE',
  AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_START = 'AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_START',
  AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_SUCCESS = 'AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_SUCCESS',
  AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_FAILURE = 'AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE_FAILURE',
  CLEAR_AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE = 'CLEAR_AGGREGATE_GIFTEE_BOX_SELECTED_CONTENT_PERCENTAGE',
  ADD_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG = 'ADD_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG',
  REMOVE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG = 'REMOVE_ADDING_BULK_GIFT_CARD_ISSUE_CONFIG',
  INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW = 'INITIALIZE_BULK_GIFTEE_BOX_ISSUE_CONFIGS_NEW',
  ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_START = 'ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_START',
  ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS = 'ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_SUCCESS',
  ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE = 'ADD_BULK_GIFT_CARD_ISSUE_CONFIGS_FAILURE',
  ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_START = 'ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_START',
  ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS = 'ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_SUCCESS',
  ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE = 'ADD_BULK_GIFTEE_BOX_ISSUE_CONFIGS_FAILURE',
  UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_INITIAL_POINT = 'UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_INITIAL_POINT',
  UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT = 'UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_REQUESTED_ISSUE_AMOUNT',
  UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_DELIVERY_DATE = 'UPDATE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG_DELIVERY_DATE',
  ADD_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG = 'ADD_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG',
  REMOVE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG = 'REMOVE_ADDING_BULK_GIFTEE_BOX_ISSUE_CONFIG',
  MOUNT_EXCHANGE_REPORT = 'MOUNT_EXCHANGE_REPORT',
  GET_PROJECT_INITIAL_BULK_DELIVERABLES_START = 'GET_PROJECT_INITIAL_BULK_DELIVERABLES_START',
  GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS = 'GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS',
  GET_PROJECT_INITIAL_BULK_DELIVERABLES_FAILURE = 'GET_PROJECT_INITIAL_BULK_DELIVERABLES_FAILURE',
  GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_START = 'GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_START',
  GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS = 'GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS',
  GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_FAILURE = 'GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_FAILURE',
  GET_PROJECT_PURPOSE_CATEGORIES_START = 'GET_PROJECT_PURPOSE_CATEGORIES_START',
  GET_PROJECT_PURPOSE_CATEGORIES_SUCCESS = 'GET_PROJECT_PURPOSE_CATEGORIES_SUCCESS',
  GET_PROJECT_PURPOSE_CATEGORIES_FAILURE = 'GET_PROJECT_PURPOSE_CATEGORIES_FAILURE',
  EXIT_PROJECT_DETAIL = 'EXIT_PROJECT_DETAIL'
}
